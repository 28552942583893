import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Modal, message } from 'antd';
import { AppContext } from './context';
import { APP_VERSION, toast, isMobile, getDeviceInfo, BASE_SYSTEM_ID, TAGGING_SYSTEM_ID, COLLECTION_POINT_SYSTEM_ID, LOGISTIC_SYSTEM_ID, INVOICING_SYSTEM_ID, BARCODE_SYSTEM_ID, WEB_ORDERING_SYSTEM_ID, HOTEL_SYSTEM_ID } from './utils';
import Loading from 'shared/components/Loading';
import TermsOfServicePage from 'shared/TermsOfServicePage';
import PrivacyPolicyPage from 'shared/PrivacyPolicyPage';
import ErrorBoundary from 'shared/components/ErrorBoundary';
import 'App.css';

import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
// import "firebase/messaging";

message.config({
  top: 40,
  duration: 1
});

const AsyncSignInPage = lazy(() => import('./shared/SignInPage'));
const AsyncDelivererApp = lazy(() => import('./delivererApp/AppShell'));
const AsyncBusinessApp = lazy(() => import('./businessApp/AppShell'));
const AsyncBusinessKiosk = lazy(() => import('./kioskApp/AppShell'));
const AsyncFactoryApp = lazy(() => import('./factoryApp/AppShell'));
const AsyncSuperAdminApp = lazy(() => import('./superAdminConsole/AppShell'));
const AsyncFactoryConsole = lazy(() => import('./factoryConsole/AppShell'));
const AsyncBusinessConsole = lazy(() => import('./businessConsole/AppShell'));

firebase.initializeApp({
  apiKey: "AIzaSyAFDyGNPz1uMoqC1-Cb6fgognxnshi3tao",
  authDomain: "easybus-laundry.firebaseapp.com",
  databaseURL: "https://easybus-laundry.firebaseio.com",
  projectId: "easybus-laundry",
  storageBucket: "easybus-laundry.appspot.com",
  messagingSenderId: "722443992238",
  appId: "1:722443992238:web:0dd960d3b7c8aa51fa013f"
});

const firestore = firebase.firestore();
const settings = {/* your settings... */ timestampsInSnapshots: true};
firestore.settings(settings);
// const messaging = firebase.messaging();
// saging.usePublicVapidKey("BOdw52Ll8D9tRC0JZd_juhFBGDkGBJK5deo_KY-HexN_8OPGOSABpXaIODV9pzzC6Svb_K9pQLjN2wMZEE6lL0k");

export default class App extends Component {
  state = {}
  componentDidMount() {
    this._mounted = true;

    if (isMobile()) {
      console.log('OFFLINE ENABLED');
      try {
        // firestore.enablePersistence().catch(err => console.error(err));
      } catch (err) {
        console.error(err);
      }
    }

    firebase.auth().onAuthStateChanged((account) => {
        if (!this._mounted) return;

        if (account) {
          account.getIdTokenResult().then(({claims}) => {
            this.setState({account: account||null, claims});
          });

          function refreshedToken(token) {
            firestore.doc(`subscriptions/${account.uid}`)
              .set({token:token||firestore.FieldValue.delete()}, {merge: true})
          }

          // Callback fired if Instance ID token is updated.
          // messaging.onTokenRefresh(() => {
          //   messaging.getToken().then(refreshedToken);
          // });

          // messaging.requestPermission()
          //   .then(() => messaging.getToken())
          //   .then(refreshedToken)
          //   .catch((err) => {
          //     console.log('Unable to get permission to notify.', err);
          //   });

          // Device remove monitor
          getDeviceInfo()
            .then(({fingerprint, ...others}) => firebase.firestore().doc(`devices/${fingerprint}`).set({
              ...others,
              appVersion: APP_VERSION,
              ...(account.displayName&&{account: account.displayName}),
              ...(account.email&&{email: account.email}),
              date: new Date(),
              active: true
            }, {merge: true}))
            .catch(err => console.error(err));
        } else {
          this.setState({account: null, claims: null});
        }
      });

    const currentVersion = localStorage.getItem('appVersion');
    if (currentVersion!==APP_VERSION) {
      localStorage.setItem('appVersion', APP_VERSION);
      toast(`New version ${APP_VERSION} updated`, 'success');
    }
  }
  componentWillUnmount() {
    this._mounted = false;
    getDeviceInfo()
      .then(({fingerprint}) => firebase.firestore().doc(`devices/${fingerprint}`).set({
        active: false
      }, {merge: true}))
      .catch(err => console.error(err));
  }
  setContext = (key, value) => {
    this.setState({contextUpdate: {...this.state.contextUpdate, [key]: value}});
  }
  render() {
    const { account, claims, contextUpdate } = this.state;
    const { accountRole, language, factoryId, businessId, fontSize,
      currencyMark, receiptPrinter, businessType, shortName, ...permissions } = claims||{};
    const context = { ...contextUpdate, fontSize: fontSize||'large-font',
      setContext: this.setContext, firebase, account: account, chinese: language==='cn', accountRole,
      ...(receiptPrinter&&{receiptPrinter}), ...(factoryId&&{factoryId}),
      ...(businessType&&{businessType}), ...(shortName&&{loginShortName: shortName}),
      ...(businessId&&{businessId}), ...(currencyMark&&{currencyMark}), [HOTEL_SYSTEM_ID]: permissions[HOTEL_SYSTEM_ID],
      [BASE_SYSTEM_ID]: permissions[BASE_SYSTEM_ID], [TAGGING_SYSTEM_ID]: permissions[TAGGING_SYSTEM_ID],
      [COLLECTION_POINT_SYSTEM_ID]: permissions[COLLECTION_POINT_SYSTEM_ID], [LOGISTIC_SYSTEM_ID]: permissions[LOGISTIC_SYSTEM_ID],
      [INVOICING_SYSTEM_ID]: permissions[INVOICING_SYSTEM_ID], [BARCODE_SYSTEM_ID]: permissions[BARCODE_SYSTEM_ID],
      [WEB_ORDERING_SYSTEM_ID]: permissions[WEB_ORDERING_SYSTEM_ID]
    };

    let app, redirect;
    switch (accountRole) {
      case 'SUPER_ADMIN': {
        if (contextUpdate&&contextUpdate.factoryId) {
          app = {
            path: '/factory/console',
            component: AsyncFactoryConsole
          };
        } else if (contextUpdate&&contextUpdate.businessId) {
          app = {
            path: '/business/console',
            component: isMobile()?AsyncBusinessApp:AsyncBusinessConsole
          };
        } else {
          app = {
            path: '/superadmin',
            component: AsyncSuperAdminApp
          };
        }
        break;
      }
      case 'BUSINESS_AGENT': {
        app = {
          path: '/business/console',
          component: AsyncBusinessConsole
        };
        break;
      }
      case 'BUSINESS_MANAGER':
      case 'BUSINESS_WORKER':
        if (false) {
          app = {
            path: '/business/kiosk',
            component: AsyncBusinessKiosk
          }
        } else if ((accountRole==='BUSINESS_MANAGER')&&!isMobile()) {
          app = {
            path: '/business/console',
            component: AsyncBusinessConsole
          };
        } else {
          app = {
            path: '/business/app',
            component: AsyncBusinessApp
          };
        }
        break;
      case 'FACTORY_MANAGER':
        app = {
          path: '/factory/console',
          component: AsyncFactoryConsole
        };
        break;
      case 'FACTORY_WORKER':
        if (isMobile()) {
          app = {
            path: '/factory/app',
            component: AsyncFactoryApp
          };
        } else {
          app = {
            path: '/factory/console',
            component: AsyncFactoryConsole
          };
        }
        break;
      case 'DELIVERER':
        app = {
          path: '/deliverer/app',
          component: AsyncDelivererApp
        };
        break;
      default:
        if (account===null) {
          redirect = '/login';
        } else if (account) {
          firebase.auth().signOut();
          Modal.confirm({
            title: `Account ${account.email||''} is not supported!`,
            content: `Please make sure you are sign in with the correct account, don't create new account on your behalf!`
          });
          redirect = '/login';
        }
    }

    return (
      <AppContext.Provider value={context}>
        <ErrorBoundary>
          <BrowserRouter>
            <Suspense fallback={<Loading/>}>
              <Switch>
                {!account&&<Route exact path="/login" component={AsyncSignInPage}/>}
                <Route exact path="/privacy-policy" component={PrivacyPolicyPage}/>
                <Route exact path="/terms-of-service" component={TermsOfServicePage}/>
                {app&&<Route path={app.path} component={app.component}/>}
                {(redirect||app)&&<Redirect push={false} to={redirect||app.path}/>}
                <Route path="/" component={Loading}/>
              </Switch>
            </Suspense>
          </BrowserRouter>
        </ErrorBoundary>
      </AppContext.Provider>
    );
  }
}