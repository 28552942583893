export const SINGAPORE_DISTRICTS = [
  { code: '01', name: 'Marina' },
  { code: '02', name: 'Marina' },
  { code: '03', name: 'Raffles Place' },
  { code: '04', name: 'Raffles Place' },
  { code: '05', name: 'People\'s Park' },
  { code: '06', name: 'Cecil' },
  { code: '07', name: 'Tanjong Pagar' },
  { code: '08', name: 'Anson' },
  { code: '14', name: 'Queenstown' },
  { code: '15', name: 'Tiong Bahru' },
  { code: '16', name: 'Tiong Bahru' },
  { code: '09', name: 'Harbourfront' },
  { code: '10', name: 'Telok Blangah' },
  { code: '11', name: 'Pasir Panjang' },
  { code: '12', name: 'Clementi' },
  { code: '13', name: 'Clementi' },
  { code: '17', name: 'High Street' },
  { code: '18', name: 'Middle Road' },
  { code: '19', name: 'Golden Mile' },
  { code: '20', name: 'Little India' },
  { code: '21', name: 'Little India' },
  { code: '22', name: 'Cairnhill' },
  { code: '23', name: 'Orchard' },
  { code: '24', name: 'Tanglin' },
  { code: '25', name: 'Ardmore' },
  { code: '26', name: 'Bukit Timah' },
  { code: '27', name: 'Holland' },
  { code: '28', name: 'Watten Estate' },
  { code: '29', name: 'Thomson' },
  { code: '30', name: 'Novena' },
  { code: '31', name: 'Toa Payoh' },
  { code: '32', name: 'Balestier' },
  { code: '33', name: 'Balestier' },
  { code: '34', name: 'Macpherson' },
  { code: '35', name: 'Braddel' },
  { code: '36', name: 'Macpherson' },
  { code: '37', name: 'Macpherson' },
  { code: '38', name: 'Geylang' },
  { code: '39', name: 'Geylang' },
  { code: '40', name: 'Eunos' },
  { code: '41', name: 'Eunos' },
  { code: '42', name: 'Joo Chiat' },
  { code: '43', name: 'Katong' },
  { code: '44', name: 'Amber Road' },
  { code: '45', name: 'Siglap' },
  { code: '46', name: 'Upper East Coast' },
  { code: '47', name: 'Bedok' },
  { code: '48', name: 'Eastwood' },
  { code: '49', name: 'Changi' },
  { code: '50', name: 'Loyang' },
  { code: '81', name: 'Changi' },
  { code: '51', name: 'Pasir Ris' },
  { code: '52', name: 'Tampines' },
  { code: '53', name: 'Hougang' },
  { code: '54', name: 'Hougang' },
  { code: '55', name: 'Serangoon Garden' },
  { code: '82', name: 'Punggol' },
  { code: '56', name: 'Ang Mo Kio' },
  { code: '57', name: 'Bishan' },
  { code: '58', name: 'Upper Bukit Timah' },
  { code: '59', name: 'Clementi' },
  { code: '60', name: 'Jurong' },
  { code: '61', name: 'Jurong' },
  { code: '62', name: 'Jurong' },
  { code: '63', name: 'Jurong' },
  { code: '64', name:  'Jurong' },
  { code: '65', name: 'Bukit Batok' },
  { code: '66', name: 'Hillview' },
  { code: '67', name: 'Bukit Panjang' },
  { code: '68', name: 'Choa Chu Kang' },
  { code: '69', name: 'Tengah' },
  { code: '70', name: 'Lim Chu Kang' },
  { code: '71', name: 'Lim Chu Kang' },
  { code: '72', name: 'Kranji' },
  { code: '73', name: 'Woodlands' },
  { code: '75', name: 'Sembawang' },
  { code: '76', name: 'Yishun' },
  { code: '78', name: 'Upper Thomson' },
  { code: '79', name: 'Seletar' },
  { code: '80', name: 'Seletar' },
  { code: '77', name: 'Sembawang' }
];

export const DEPARTMENT_CLOTH = 'CLOTH';
export const DEPARTMENT_LINEN = 'LINEN';

export const WORKER_ROLE_TAGGER = 'TAGGER';
export const WORKER_ROLE_WASHER = 'WASHER';
export const WORKER_ROLE_IRONINGER = 'IRONINGER';
export const WORKER_ROLE_SORTER = 'SORTER';
export const WORKER_ROLE_PACKER = 'PACKER';
export const WORKER_ROLE_CUSTOMER_SERVICE = 'CUSTOMER_SERVICE';
export const WORKER_ROLE_DRIVER = 'DRIVER';
export const WORKER_ROLE_MANAGER = 'MANAGER';

export const CORPORATE_TYPE_HOTEL = 'Hotel';

export const HONESTBEE_BUS_NAME = 'Honestbee';
export const KNOCKNOCK_BUS_NAME = 'KnocKnocK';

export const HOTEL_DEPARTMENT_LINEN = 'Hotel Linen';
export const HOTEL_DEPARTMENT_UNIFORM = 'Staff Uniform';
export const HOTEL_DEPARTMENT_FNB = 'F&B';
export const HOTEL_DEPARTMENT_GYM = 'GYM Linen';
export const HOTEL_DEPARTMENT_GUEST_LAUNDRY = 'Guest Laundry';
export const HOTEL_DEPARTMENT_POOL = 'Pool';
export const HOTEL_DEPARTMENT_OTHERS = 'Others';

export const WASH_INSTRUCTION_PROCEED = 'Proceed';
export const WASH_INSTRUCTION_AWAITING = 'Awaiting';
export const WASH_INSTRUCTION_REJECT = 'Reject';

export const STATUS_PENDING = 'Pending';
export const STATUS_SOLVING = 'Solving';
export const STATUS_CLOSED = 'Closed';

export const BUS_SYMBOL_SQUQRE = 'SQUQRE';

export const ORDERS_INDEX_TYPE_PACK = 'PACK';
export const ORDERS_INDEX_TYPE_TAG = 'TAG';

export const ACCOUNT_ROLE_SUPER_ADMIN = 'SUPER_ADMIN';
export const ACCOUNT_ROLE_FACTORY_MANAGER = 'FACTORY_MANAGER';
export const ACCOUNT_ROLE_FACTORY_WORKER = 'FACTORY_WORKER';
export const ACCOUNT_ROLE_BUSINESS_MANAGER = 'BUSINESS_MANAGER';
export const ACCOUNT_ROLE_BUSINESS_AGENT = 'BUSINESS_AGENT';
export const ACCOUNT_ROLE_BUSINESS_WORKER = 'BUSINESS_WORKER';
export const ACCOUNT_ROLE_DELIVERER = 'DELIVERER';

export const PROMO_TYPE_FLAT = 'flat';
export const PROMO_TYPE_PERCENT = 'percent';

export const TYPE_FLAT = 'flat';
export const TYPE_PERCENTAGE = 'percentage';

export const CONTRACT_STATUS_DRAFT = 'draft';
export const CONTRACT_STATUS_REVIEWING = 'reviewing';
export const CONTRACT_STATUS_ACTIVE = 'active';
export const CONTRACT_STATUS_DISCARD = 'discard';

export const SETTLEMENT_TYPE_FIXED = 'fixed';
export const SETTLEMENT_TYPE_SHARE = 'share';

export const SIGNATURE_TYPE_DRIVER = 'Driver';
export const SIGNATURE_TYPE_HOTEL = 'Hotel';

export const LINEN_PROCESS_PICKUP = 'Pickup';
export const LINEN_PROCESS_COUNT = 'Count';
export const LINEN_PROCESS_DELIVER = 'Deliver';

export const CASE_PARTY_WORKER = 'Worker';
export const CASE_PARTY_CUSTOMER_SERVICE = 'CS';
export const CASE_PARTY_DIRECTOR = 'Director';

export const ITEM_TYPE_REWASH = 'rewash';
export const ITEM_TYPE_SPECIAL = 'special';
export const ITEM_TYPE_NEW = 'newitem';

export const PERMISSIONS_FACTORY_APP = [
  { key: 'dutyCases', description: `Report/Update Cases Page` },
  { key: 'dutyNewOrder', description: `Quick Order Page (For old people create order at factory only / TABLE ONLY)` },
  { key: 'dutyCheckIn', description: `Factory Check In Page (Old)` },
  { key: 'dutyNewCheckIn', description: `Factory Check In Page (New)` },
  { key: 'dutyExpressCheckIn', description: `Factory Order Monitor Page` },
  { key: 'dutyCreateOrder', description: `New Order Page` },
  { key: 'dutyTagging', description: `Factory Tagging Page` },
  { key: 'barcodeQC', description: `Factory Issue QC Page (Starting)` },
  { key: 'dutyOrderQC', description: `Factory Issue QC Page (Direct)` },
  { key: 'dutyManageQC', description: `Factory QC Management Page` },
  { key: 'dutySorting', description: `Factory Sorting Page` },
  { key: 'dutyPackaging', description: `Factory Packing Page (Old)` },
  { key: 'dutyStorage', description: `Factory Packing & Storage Page (New)` },
  { key: 'dutyPacking', description: `Factory Packing & Storage Page (New Direct)` },
  { key: 'dutyCheckOut', description: `Factory Check Out Page (Old)` },
  { key: 'dutyNewCheckOut', description: `Factory Check Out Page (New)` },
  { key: 'dutyCheckOutSimply', description: `Factory Check Out Page (Simplified)` },
  { key: 'dutyInvoicePrint', description: `Factory Receipt Printing Page` },
  { key: 'dutyHotelPD', description: `Hotel Pickup & Delivery Page` },
  { key: 'dutyHotelCounting', description: `Hotel Counting Page` },
  { key: 'dutyPickup', description: `Shop Pickup Page` },
  { key: 'dutyDelivery', description: `Shop Delivery Page` },
  { key: 'dutyCollection', description: `Customer Delivery Page` },
  // { key: 'dutyNTUC', description: `Barcode Batch Processing` },
  { key: 'barcodeSetup', description: `Barcode Setup Page (Initial photo tacking)` },
  { key: 'limitHistory', description: `Order history limit to today` },
  { key: 'allHistory', description: `View all order history from others workers` },
  { key: 'limitBusiness', description: `Limit check in/out to only business assigned to worker` },
];

export const PERMISSIONS_FACTORY_ADMIN = [
  { key: 'dutyDashboard', description: `View Dashboard` },
  { key: 'dutyBusiness', description: `Manage Business` },
  { key: 'dutyHotel', description: `Manage Hotels` },
  { key: 'dutyPaymentCollect', description: `Collect & Monitor Payments` },
  { key: 'dutyHandover', description: `Payments Handover` },
  { key: 'dutyPayment', description: `Manage Payment (Work as Accountant)` },
  { key: 'dutyWorker', description: `Manage Workers` },
  { key: 'dutyLinking', description: `Manage Accounting Linkings` },
  { key: 'dutyIntegration', description: `Manage System Integrations` },
  { key: 'dutyItem', description: `Manage Pricings` },
  { key: 'dutyOrder', description: `Manage Orders` },
  { key: 'dutyQC', description: `Manage Quality Control Issues` },
  { key: 'dutyCases', description: `Manage Cases` },
  { key: 'dutyBarcode', description: `Barcode processing` },
  { key: 'dutySales', description: `View Sales & Invoicing` },
  { key: 'dutyInvoice', description: `Issue & manage invoice` },
  { key: 'dutySetting', description: `Manage Factory Settings` },
];

export const PERMISSIONS_COMMON = [
  { key: 'dutyEditOrder', description: `Can edit order` },
  { key: 'dutyDeleteOrder', description: `Can delete order` }
];

export const PERMISSIONS_HARDWARES = [
  { key: 'useScanner', description: `Bluetooth Scanner` },
  { key: 'usePrinter', description: `Bluetooth Receipt Printer` },
  { key: 'useLabelPrinter', description: `Bluetooth Label Printer` }
];

export const PERMISSIONS_BUSINESS_APP = [
  { key: 'dutyManageCustomer', description: `Manage Customer` },
  { key: 'dutyDeleteCustomer', description: `Delete Customer` },
  { key: 'dutySalesDashboard', description: `Sales Dashboard` },
  { key: 'dutyCashCollection', description: `Cash Collection board` },
  { key: 'dutySalesReport', description: `Daily sales report` },
];