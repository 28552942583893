import React from 'react';
import { Modal, List } from 'antd';
import moment from 'moment';
import { toast, LABEL_PLACEHOLDERS, calculateGrandTotalDetails, formatItemsDisplays,
  getDisplayId, getCustomerDisplay, PAYMENT_STATUS_PENDING, PAYMENT_STATUS_SUCCEED, UNIT_PCS } from './utils';
import { reset, alignMiddle, write, fontSize, alignLeft, newLine,
  columnsWrite, divider, bold, box, barcode, image, alignRight, cut, font, reverse, qrcode, rowGap } from './receipter';
import { formatPrice } from './shares';
import { HONESTBEE_BUS_NAME, KNOCKNOCK_BUS_NAME } from './constants';

export const USB_LABEL_PRINTER_VENDOR_ID = 1137;
export const USB_LABEL_PRINTER_PRODUCT_ID = 85;
export const USB_RECEIPT_PRINTER_VENDOR_ID = 26728;
export const USB_RECEIPT_PRINTER_PRODUCT_ID = 1280;

export async function findUSBDevice(vendorId, productId, devices) {
  const printer = devices&&devices.find(device=>(device.vendorId===vendorId)&&(device.productId===productId));
  if (printer) {
    try {
      await printer.open();
      await printer.selectConfiguration(1);
      await printer.claimInterface(0);
      return printer;
    } catch(err) {
      // ingore
      alert(err.message);
      return null;
    }
  }
}

export function getWeekCode(date) {
  switch(date.day()) {
    case 1: return 'A';
    case 2: return 'B';
    case 3: return 'C';
    case 4: return 'D';
    case 5: return 'E';
    case 6: return 'F';
    case 0: return 'G';
  }
}

export function renderLabelNote(order) {
  if (order.get('tagNotes')||order.get('dayIndex')) {
    const created = order.get('created');
    const day = (created&&created.unix&&getWeekCode(moment.unix(created.unix.seconds)))||'';
    return `${day||''}${order.get('tagNotes')||order.get('dayIndex')||''}`;
  } else {
    return '';
  }
}

function renderLabel(labelFormat, order, item, {total, laundryTypes}) {
  if (!labelFormat) {
    return `${getDisplayId(order)}/${total}`;
  }
  
  let placeholder, protection=20;
  do {
    protection--;
    placeholder = LABEL_PLACEHOLDERS.find(item => labelFormat.indexOf(`@${item}`)>=0);

    if (placeholder) {
      let replacement = '';
      switch(placeholder) {
        case 'ORDER_NO':
          replacement = getDisplayId(order);
          break;
        case 'TOTAL_QUANTITY':
          replacement = `${total}`;
          break;
        case 'BUSINESS_NAME':
          const business = order.get('business');
          replacement = (business&&(business.tagName||business.displayName))||'';
          break;
        case 'DELIVERY_DATE': {
          const delivery = order.get('tagging')||order.get('delivery');
          replacement = (delivery&&delivery.unix&&moment.unix(delivery.unix.seconds).format('D/M'))||'';
          break;
        }
        case 'WASH_TYPE_CODE':
          replacement = (item&&item.labelCode)||'';
          break;
        case 'WASH_TYPE_QUANTITY':
          replacement = (item&&item.labelCode&&(item.labelCodeQty||calculatePcsQty(laundryTypes[item.labelCode]||[], false, true)))||'';
          break;
        case 'ORDER_TAG_NOTE':
          replacement = renderLabelNote(order);
          break;
        case 'CREATION_DAY': {
          replacement = ``;
          break;
        }
        case 'COLLECTION_STORAGE': {
          replacement = order.get('storage');
          break;
        }
        case 'STORAGE_TYPES': {
          replacement = (item&&item.storeType)
            ||(order.get('foldQty')&&!order.get('hangQty')&&'F')
            ||(!order.get('foldQty')&&order.get('hangQty')&&'H')
            ||'';
          break;
        }
        case 'ADDON_SERVICES': {
          replacement = (item&&item.services&&`${item.services.map(svc=>svc.labelCode).join('/')}`)||'';
          break;
        }
      }
  
      const params = labelFormat.match(new RegExp(`@${placeholder}=\{(.*?)\}`, 'i'));
      let regHolder;
      if (params) {
        regHolder = params[0];
        if (params[1]!==replacement) {
          replacement = '';
        }
      } else {
        regHolder = `@${placeholder}`;
      }
  
      labelFormat = labelFormat.replace(new RegExp(regHolder, 'g'), replacement||'');
    }
  } while(placeholder&&(protection>0));

  labelFormat = labelFormat.trim();
  labelFormat = labelFormat.replace(/ {1,}/g," ");

  if (item&&item.accs) {
    labelFormat = `${labelFormat} (${item.accsMark}${item.accs})`;
  }

  return labelFormat;
}

export function calculatePcsQty(items, includeReject, includeAccessory, departmentFilter) {
  const totalQty = [0, ...(items||[]).map(({quantity,reject,inputQty,bundleQty,fixedQty,accs,tagQty,fixedTag,department})=>{
    if (departmentFilter&&(departmentFilter!==department)) {
      return 0;
    } else if (fixedTag) {
      return tagQty||1;
    // Backward support only
    } else if (tagQty) {
      return parseInt(quantity||0)*(tagQty||1) - ((!includeReject&&reject)||0);
    } else {
      // New calculation
      const changes = ((includeAccessory&&accs)||0) - ((!includeReject&&reject)||0);
      if (typeof fixedQty === 'number') {
        return fixedQty;
      } else if (inputQty) {
        return parseInt(inputQty + changes);
      } else if (bundleQty) {
        return parseInt(quantity||0)*bundleQty + changes;
      } else {
        return parseInt(quantity||0) + changes;
      }
    }
  })].reduce((a,b)=>a+b);
  return totalQty;
}

export function calculateSortingQty(order) {
  const total = (order.get('tagQuantity')||calculatePcsQty(order.get('items'), true, true));
  const sortings = order.get('sortings');
  const sorted = sortings?[0, ...Object.values(sortings)].reduce((a,b)=>a+b):0;

  return { sorted, total };
}

function leftPad(number, size) {
  var s = String(number);
  while (s.length < (size || 2)) {s = "0" + s;}
  return s;
}

async function runBarcodePrinting(labeler, barcodeWidth, content, displayText, large) {
  await write(labeler, `SIZE 110 mm, ${barcodeWidth} mm\n`);
  await write(labeler, 'GAP 0,0\n');
  await write(labeler, 'SPEED 6\n');
  await write(labeler, 'DENSITY 12\n');
  await write(labeler, 'SET CUTTER 1\n');
  await write(labeler, 'CLS\n');
  let offset = 0, times = 4;
  if (barcodeWidth <= 10) {
    times = 1;
  } else if (barcodeWidth <= 15) {
    times = 2;
  } else if (barcodeWidth <= 20) {
    times = 3;
  }
  if (large&&displayText) {
    await write(labeler, `TEXT 0,8,"5",0,${times},${times},"${displayText}"\n`);
    const headrWidth = displayText.length*36*times;
    offset = headrWidth + 16*times;
  }
  const barcodeHeight = parseInt((large?5:4)*barcodeWidth);
  await write(labeler, `BARCODE ${16+(offset)},8,"128",${barcodeHeight},0,0,${large?`3,7`:`2,5`},"${content}"\n`);
  if (!large&&displayText) {
    await write(labeler, `TEXT 0,${barcodeHeight+16},"3",0,1,1,"${displayText}"\n`);
  }
  if (large&&displayText&&(times<3)) {
    const totalWidth = 104*8;
    const headrWidth = displayText.length*36*times;

    await write(labeler, `TEXT ${totalWidth-headrWidth},8,"5",0,${times},${times},"${displayText}"\n`);
  }
  await write(labeler, `PRINT 1,1\n`);
}

export async function printBarcode(labeler, prefix, barcodeWidth, displayText, start, end, large) {
  if (!labeler) {
    toast(`Please connect label printer!`);
    return false;
  }

  if ((typeof start === 'number')
   &&(typeof end === 'number')
   &&(start <= end)) {
    while (start <= end) {
      await runBarcodePrinting(labeler, barcodeWidth, `${prefix}@${leftPad(start, 2)}`, displayText, large);
      start++;
    }
    return true;
  } else {
    await runBarcodePrinting(labeler, barcodeWidth, `${prefix}`, displayText, large);
  }
}

export function isStickerPrinter(printer) {
  if (printer&&printer.properties&&!printer.properties.indicate&&
      printer.service&&printer.service.device&&(`${printer.service.device.name}`.indexOf('Printer_')===0)) {
    return true;
  }
}

export async function printSticker(printer, order, {storeOnSticker, stickerWidth=50, stickerHeight=40, stickerGap=2, titleIndex, barcodeIndex=1}) {
  const displayName = order.get('business').displayName.split(' ');
  const packsPcs = order.get('packsPcs');
  const pcs = packsPcs&&(typeof barcodeIndex === 'number')&&packsPcs[barcodeIndex-1];
  const maxWord = parseInt(stickerWidth / 2.6);
  let textLines = [``];
  while (displayName.length) {
    const last = textLines[textLines.length-1];
    const word = displayName.splice(0, 1);
    const newWord = `${last?`${last} `:''}${word}`;
    if (newWord.length<=maxWord) {
      textLines[textLines.length-1] = newWord;
    } else {
      textLines.push(word);
    }
  }
  const storages = storeOnSticker&&(order.get('storage')?[order.get('storage')]:order.get('storages'));
  if (storages&&(storages.length>0)) {
    textLines.push(storages.join(', '));
  }

  const displayId = getDisplayId(order);
  let commands = [
    `SIZE ${stickerWidth} mm, ${stickerHeight} mm\n`,
    `GAP ${stickerGap} mm, 0 mm\n`,
    `SPEED 6\n`,
    `DENSITY 15\n`,
    `CLS\n`,
    `TEXT 10,30,${(displayId.length>7)?`"5",0,1,1`:`"4",0,2,2`},"${displayId}"\n`,
    `QRCODE 350,10,L,4,M,0,1,1,"${displayId}"\n`,
    `BARCODE 10,110,"EAN128",80,0,0,2,5,"${displayId}@${barcodeIndex}"\n`,
    ...(pcs?[
      `TEXT 300,110,"4",0,1,1,"${pcs} Pcs"\n`,
      `TEXT 300,155,"4",0,1,1,"${titleIndex||''}"\n`,
    ]:[
      `TEXT 320,130,"5",0,1,1,"${titleIndex||''}"\n`
    ]),
    ...textLines.map((word, index) => `TEXT 10,${200+(index*40)},"4",0,1,1,"${word}"\n`),
    `PRINT 1,1\n`,
    `CLS\n`,
  ];

  await runLabelCommands(printer, [{quantity:1,commands}])
}

function getTagCommands(labelWidth) {
  return [`SIZE 110 mm, ${labelWidth||9} mm\n`, `GAP 0 mm,0 mm\n`, `SPEED 5\n`, `DENSITY 12\n`, `SET CUTTER 1\n`, `CLS\n`];
}

export async function printLabel(labeler, order,
  { fontSize, printQty, tagQuantity, chinese, largeLabel, hideBarcode, useQRCode,
    additionalTagSmall, additionalTag, labelFormat, busSymbol, attachTagIndex, labelWidth}) {
  if (!labeler) {
    toast(chinese?`请连接标签打印机`:`Please connect label printer!`);
    return false;
  }

  const items = order.get('items')||[];
  const total = calculatePcsQty(items, false, true);
  if (total===0) {
    if (!tagQuantity) {
      toast(chinese?`订单为空`:`Order is empty`);
      return false;
    }
  }

  const barcodeContent = `${getDisplayId(order)}`;
  let tagName = order.get('business').tagName;

  let laundryTypes = {};
  items.forEach(item => {
    const laundryType = item.labelCode;
    if (laundryTypes[laundryType]) {
      laundryTypes[laundryType].push(item);
    } else {
      laundryTypes[laundryType] = [item];
    }
  });

  // Set label width
  let labels = items.map(item => {
    const content = renderLabel(labelFormat, order, item, { total, laundryTypes });
    const quantity = calculatePcsQty([item], false, true);
    return { content, quantity };
  });

  if (labels.length === 0) {
    // Empty orders
    if (order.get('laundryQty')||order.get('dryCleanQty')||order.get('pressingQty')) {
      [...order.get('laundryQty')?[{labelCode:'L',qty:order.get('laundryQty')}]:[],
        ...order.get('dryCleanQty')?[{labelCode:'D',qty:order.get('dryCleanQty')}]:[],
        ...order.get('pressingQty')?[{labelCode:'P',qty:order.get('pressingQty')}]:[]]
        .map(({labelCode, qty}) => {
          const content = renderLabel(labelFormat, order, {labelCode, labelCodeQty: qty}, { total: tagQuantity||printQty, laundryTypes });
          labels.push({ content, quantity: qty });
        })
    } else {
      const content = renderLabel(labelFormat, order, null, { total: tagQuantity||printQty, laundryTypes });
      labels.push({ content, quantity: printQty||tagQuantity });
    }
  }

  let labelIndex = order.get('labelIndex')||0;

  if (!printQty||(printQty===(order.get('printQty')||total||tagQuantity))) {
    // Print all one by one
    labels = [].concat.apply([], labels.map(label => {
      return Array.apply(null, Array(label.quantity)).map(() => {
        labelIndex++;
        return {content: label.content, quantity: 1, commands: [...getTagCommands(labelWidth),
          ...calculateLabelCommands(tagName, label.content, hideBarcode?null:`${barcodeContent}#${labelIndex}`, {largeLabel, busSymbol, useQRCode, attachTagIndex: attachTagIndex&&renderLabelNote(order)})]}
      });
    }));
    order.ref.update({labelIndex});

    if (typeof additionalTag === 'number') {
      labels = [
        {content: ``, quantity: additionalTag, commands: [...getTagCommands(additionalTagSmall?labelWidth:20),
          ...calculateLabelCommands(barcodeContent, `${renderLabelNote(order)}${tagName?` ${tagName}`:''}`, barcodeContent, {largeLabel: true, largeTag: !additionalTagSmall})]},
        ...labels
      ];
    }

    await runLabelCommands(labeler, labels);
  } else {
    if (typeof additionalTag === 'number') {
      labels.push({content: `ORDER TAG`, quantity: additionalTag, special:true});
    }

    // Select print
    const differentLabels = labels.filter((label1, index) => labels.findIndex(label2=>label2.content===label1.content)===index);
    if (differentLabels.length > 1) {
      const modal = Modal.confirm({
        title: <span className={`${fontSize}`}>Choose Label</span>,
        content: <List dataSource={differentLabels} renderItem={label=>
          <List.Item className='hover-selectable' onClick={()=>{
            let printLabels = Array.apply(null, Array(printQty)).map(() => {
              labelIndex++;
              if (label.special) {
                return {content: ``, quantity: 1, commands: [...getTagCommands(additionalTagSmall?labelWidth:20),
                  ...calculateLabelCommands(barcodeContent, `${renderLabelNote(order)}${tagName?` ${tagName}`:''}`, barcodeContent, {largeLabel: true, largeTag: !additionalTagSmall})]};
              } else {
                return {content: label.content, quantity: 1, commands: [...getTagCommands(labelWidth),
                  ...calculateLabelCommands(tagName, label.content, hideBarcode?null:`${barcodeContent}#${labelIndex}`, {largeLabel, busSymbol, useQRCode, attachTagIndex: attachTagIndex&&renderLabelNote(order)})]};
              }
            });

            order.ref.update({labelIndex});
            runLabelCommands(labeler, printLabels);
            modal.destroy();
          }}>
            <List.Item.Meta title={<span className={fontSize}>{label.content}</span>}/>
          </List.Item>
        }/>,
        okText: <span className={fontSize}>Cancel</span>,
        okCancel: false
      });
    } else {
      let printLabels = [];
      labels.forEach(label => {
        Array.apply(null, Array(label.quantity)).forEach(() => {
          if (printLabels.length<printQty) {
            labelIndex++;
            printLabels.push({content: label.content, quantity: 1, commands: [...getTagCommands(labelWidth),
              ...calculateLabelCommands(tagName, label.content, hideBarcode?null:`${barcodeContent}#${labelIndex}`, {largeLabel, busSymbol, useQRCode, attachTagIndex: attachTagIndex&&renderLabelNote(order)})]});
          } 
        });
      });
      if (printQty>printLabels.length) {
        const moreTag = printQty - printLabels.length;
        Array.apply(null, Array(moreTag)).forEach(() => {
          printLabels.push(printLabels[0]);
        });
      }

      order.ref.update({labelIndex});
      await runLabelCommands(labeler, printLabels);
    }
  }
}

async function runLabelCommands(labeler, labels) {
  for (const { quantity, commands } of labels) {
    for (let i = 0; i < quantity; i++) {
      await write(labeler, commands.join(''), true);
    }
  }
}

function calculateLabelCommands(tagName, printContent, barcodeContent, {largeLabel, busSymbol, largeTag, useQRCode, attachTagIndex}) {
  let startTagName = tagName, endTagName = tagName;
  if (attachTagIndex) {
    startTagName = `${attachTagIndex}-${startTagName||''}`;
    endTagName = `${endTagName||''}-${attachTagIndex}`;
  }
  const headerWidth = startTagName?(startTagName.length*24 + (busSymbol?40:0)):0;
  const stapleWidth = useQRCode?150:0;
  const headFootMargin = Math.max(headerWidth, stapleWidth);
  const barcodeWidth = barcodeContent?(useQRCode?70:(152 + barcodeContent.length*15)):0;
  const totalWidth = 104*8;
  const line0Y = largeLabel?8:20, line1Y=3, line2Y=37, headerY=8, barcodeY=4, barcodeHeight= largeTag?140:56;
  let commands = [], hideFooter, contantStart = 0, tagNameStart = busSymbol?20:0;

  if (startTagName) {
    commands.push(`TEXT ${tagNameStart+(busSymbol?2:0)},${headerY},"4",0,1,2,"${startTagName}"\n`);
    if (busSymbol) {
      commands.push(`REVERSE 0,0,${headerWidth},66\n`);
    }
    contantStart = contantStart + headFootMargin + 24;
  }
  if (useQRCode&&barcodeContent) {
    commands.push(`QRCODE ${headFootMargin},0,M,3,A,0,"${barcodeContent}"\n`);
    contantStart = contantStart + 70;
  }

  let contentWidth = totalWidth-(2*headFootMargin)-(barcodeWidth*(useQRCode?2:1))-16;
  let maxContentWidth = parseInt(contentWidth/24);
  // Hide the footer header to give more space
  if (printContent.length>(2*maxContentWidth)) {
    hideFooter = true;
    contentWidth = totalWidth-headFootMargin-barcodeWidth;
    maxContentWidth = parseInt(contentWidth/24);
  }

  // Single line
  if (largeLabel||(printContent.length<=maxContentWidth)) {
    commands.push(`TEXT ${contantStart},${line0Y},"4",0,1,${largeLabel?'2':'1'},"${printContent}"\n`);
  } else {
    // Double line
    const contents = printContent.split(' ');
    let firstLine=[], secondLine=[];
    contents.forEach(content => {
      if ([...firstLine, content].join(' ').length <= maxContentWidth) {
        firstLine.push(content);
      } else {
        secondLine.push(content);
      }
    });

    commands.push(`TEXT ${contantStart},${line1Y},"4",0,1,1,"${firstLine.join(' ')}"\n`);
    commands.push(`TEXT ${contantStart},${line2Y},"4",0,1,1,"${secondLine.join(' ')}"\n`);
  }

  if (barcodeContent) {
    if (useQRCode) {
      commands.push(`QRCODE ${totalWidth-(hideFooter?0:headFootMargin)-barcodeWidth},0,M,3,A,0,"${barcodeContent}"\n`);
    } else {
      commands.push(`BARCODE ${totalWidth-(hideFooter?0:headFootMargin)-barcodeWidth+16},${barcodeY},"EAN128",${barcodeHeight},0,0,2,5,"${barcodeContent}"\n`);
    }
  }
  if (!hideFooter&&endTagName) {
    commands.push(`TEXT ${totalWidth-headerWidth+(busSymbol?20:0)},${headerY},"4",0,1,2,"${endTagName}"\n`);
    if (busSymbol) {
      commands.push(`REVERSE ${totalWidth-headerWidth},0,${headerWidth},66\n`);
    }
  }

  commands.push(`PRINT 1,1\n`);
  return commands;
}

export async function printReceipt(printer, order, params) {
  const { internal, largeFont, hideReceiptPrice, priceHidden, hideCreatedBy,
    hideBusinessContact, hideCompany, hideLaundryType, customerTerms, contactName, contactNo,
    chinese, printBusAsCus, brandUrl, address, workingHours, uenNo, payments,
    printStoreType, deliveryKeywords, titleIndex, cornerOrderNo, cornerShortName,
    cornerNotes, barcodeIndex, internalPrintLogo, hideDeliveryDate, hideTotalQty,
    hideCustomerDetail, printChinese, departmentFilter } = params||{};
  if (!printer) {
    toast(chinese?`打印机未连接`:`Printer unconnected!`);
    return false;
  }

  const { company, creator, business, items, tagQuantity, delivery, paymentTracking,
    customer, created, notes, serviceFeeRate, gstRate, createSign, trackingId, totalPaid,
    factoryPricing, serviceTypeChargeRate, tagging, storage } = order.data();
  const { totalSurcharge, totalServiceFee, totalGST, totalPrice, subTotalPrice } = (internal&&factoryPricing)||order.data();
  const orderIndex = renderLabelNote(order);
  let fullWidth = 48, cutter = true;
  if (printer.service&&printer.service.device&&(printer.service.device.name==='DL581printer')) {
    fullWidth = 32;
    cutter = false;
  }
  if (largeFont) {
    fullWidth = parseInt(fullWidth / 2);
  }

  const hidePrice = priceHidden||(hideReceiptPrice&&internal);

  try {
    await reset(printer);
    await fontSize(printer, 2);
    if (!cutter) {
      await newLine(printer);
    }
    if (cornerNotes) {
      await write(printer, cornerNotes);
      await newLine(printer);
    }
    if (cornerOrderNo||cornerShortName) {
      await alignRight(printer);
      await write(printer, `${cornerOrderNo?`${getDisplayId(order)}`:''}${(cornerShortName&&creator.shortName)?`(${creator.shortName})`:''}`);
      await newLine(printer);
    }
    if (titleIndex) {
      await alignRight(printer);
      await write(printer, titleIndex);
      await newLine(printer);
    }
    if (internal&&(cornerOrderNo||cornerShortName)) {
      await newLine(printer);
    }
    await alignMiddle(printer);

    if (internal&&(serviceTypeChargeRate||notes)) {
      await fontSize(printer, 3);
      await reverse(printer, true);
      if (serviceTypeChargeRate) {
        const date = tagging||delivery;
        await write(printer, `${serviceTypeChargeRate} EXP ${(date&&date.unix)?`${moment.unix(date.unix.seconds).format('DD/MM')}`:``}`);
        await newLine(printer);
      }
      if (notes) {
        await write(printer, `CAUTION`);
        await newLine(printer);
      }

      await reverse(printer, false);
      await newLine(printer);
      await fontSize(printer, 2);
    }

    if (((!internal)||internalPrintLogo)&&brandUrl) {
      await image(printer, brandUrl, (fullWidth-8)*6);
    } else if (business.displayName) {
      await write(printer, `${business.displayName}${((business.displayName===HONESTBEE_BUS_NAME)||(business.displayName===KNOCKNOCK_BUS_NAME))?(storage?`-${storage}`:``):''}`);
      await newLine(printer, 2);
    }
    
    if (!cornerOrderNo) {
      await write(printer, `${getDisplayId(order)}${orderIndex?`-${orderIndex}`:''}`);
    }
    await newLine(printer, 2);
    
    if (!largeFont) {
      await fontSize(printer, 1);
    }
    await alignLeft(printer);

    if (!internal) {
      if (address||workingHours||contactNo||uenNo) {
        if (address&&(typeof address === 'string')) {
          await columnsWrite(printer, fullWidth, 'Address:', `${address}`, undefined, true);
        }
        if (workingHours) {
          await columnsWrite(printer, fullWidth, 'Working Hours:', `${workingHours}`, undefined, true);
        }
        if (contactNo) {
          await columnsWrite(printer, fullWidth, 'Contact:', `${contactNo}`, undefined, true);
        }
        if (uenNo) {
          await columnsWrite(printer, fullWidth, 'UEN No:', `${uenNo}`, undefined, true);
        }
        await newLine(printer, 1);
      }
    }
    if (trackingId) {
      await columnsWrite(printer, fullWidth, 'Tracking No:', `${trackingId}`, undefined, true);
    }
    if (customer&&(!internal||!hideCustomerDetail)) {
      if (largeFont) {
        await write(printer, 'Customer:');
        await newLine(printer);
        await alignRight(printer);
        await write(printer, getCustomerDisplay(customer));
        await newLine(printer);
        await alignLeft(printer);
      } else {
        await columnsWrite(printer, fullWidth, 'Customer:',
          getCustomerDisplay(customer, true), undefined, true);
        if (customer.address) {
          await columnsWrite(printer, fullWidth, 'Customer Address:', `${customer.unitNo?`${customer.unitNo}, `:''}${customer.address}`, undefined, true);
        }
      }
    } else if (printBusAsCus) {
      if (largeFont) {
        await write(printer, 'Customer:');
        await newLine(printer);
        await alignRight(printer);
        await write(printer, `${business.displayName}`);
        await newLine(printer);
        await alignLeft(printer);
      } else {
        await columnsWrite(printer, fullWidth, 'Customer:', `${business.displayName}`, undefined, true);
      }
    }
    if (internal) {
      if (!hideCompany&&company) {
        if (largeFont) {
          await write(printer, 'Issued By:');
          await newLine(printer);
          await alignRight(printer);
          await write(printer, company.displayName||'');
          await newLine(printer);
          await alignLeft(printer);
        } else {
          await columnsWrite(printer, fullWidth, 'Issued By:', company.displayName||'', undefined, true);
        }
      }
    }
    if (largeFont) {
      await write(printer, 'Create Date:');
      await newLine(printer);
      await alignRight(printer);
      await write(printer, moment.unix(created.unix&&created.unix.seconds).format(`HH:mm DD/MM YYYY`));
      await newLine(printer);
      await alignLeft(printer);
    } else {
      await columnsWrite(printer, fullWidth, 'Create Date:', moment.unix(created.unix&&created.unix.seconds).format(`HH:mm DD/MM YYYY`), undefined, true);
    }
    if (!hideCreatedBy) {
      if (largeFont) {
        await write(printer, 'Create By:');
        await newLine(printer);
        await alignRight(printer);
        await write(printer, creator.displayName||'');
        await newLine(printer);
        await alignLeft(printer);
      } else {
        await columnsWrite(printer, fullWidth, 'Create By:', creator.displayName||'', undefined, true);
      }
    }
    if (internal) {
      if (!hideBusinessContact&&contactNo) {
        if (largeFont) {
          await write(printer, 'Business Contact:');
          await newLine(printer);
          await alignRight(printer);
          await write(printer, `${contactNo}${contactName?` (${contactName})`:''}`);
          await newLine(printer);
          await alignLeft(printer);
        } else {
          await columnsWrite(printer, fullWidth, 'Business Contact:',
          `${contactNo}${contactName?` (${contactName})`:''}`, undefined, true);
        }
      }
    }
    if (delivery&&(!internal||!hideDeliveryDate)) {
      if (largeFont) {
        await write(printer, `${deliveryKeywords||`Delivery`} Date:`);
        await newLine(printer);
        await alignRight(printer);
        await write(printer,  moment.unix(delivery.unix&&delivery.unix.seconds).format(`DD/MM YYYY`));
        await newLine(printer);
        await alignLeft(printer);
      } else {
        await columnsWrite(printer, fullWidth, `${deliveryKeywords||'Delivery'} Date:`,
          moment.unix(delivery.unix&&delivery.unix.seconds).format(`DD/MM YYYY`), undefined, true);
      }
    }
    await newLine(printer);
    if (hidePrice) {
      await columnsWrite(printer, fullWidth, 'Item', 'Qty');
    } else {
      await columnsWrite(printer, fullWidth, 'Item', 'Unit Price  Qty', 'Price');
    }
    await alignLeft(printer);
    await divider(printer, fullWidth);
    const itemsDisplays = formatItemsDisplays(items, null, printChinese, hideLaundryType);
    if (fullWidth === 48) {
      await rowGap(printer, 80);
    }
    for (const {title, storeType, quantity, pcsQty, totalPrice, price, unit, department, accessory} of itemsDisplays) {
      if (departmentFilter&&(department!==departmentFilter)) {
        continue;
      }

      if (largeFont&&(fullWidth<20)) {
        await write(printer, title);
        await newLine(printer);
        await alignRight(printer);
        await write(printer, `${hidePrice?'':'x '}${quantity}${hidePrice?'':` = ${totalPrice.toFixed(2)}`}`);
        await newLine(printer);
        await alignLeft(printer);
      } else {
        const diffPcs = pcsQty&&(pcsQty!==quantity);
        if (hidePrice) {
          await columnsWrite(printer, fullWidth, `${title}`, `${accessory?`(${quantity})`:quantity}${(unit!==UNIT_PCS)?`/${unit}`:''}${(printStoreType&&storeType)?`${diffPcs?`(${pcsQty}`:''}${storeType}${diffPcs?')':''}`:''}`);
        } else {
          await columnsWrite(printer, fullWidth, title, `${price?`${price.toFixed(2)} x `:''}${accessory?`(${quantity})`:quantity}${(printStoreType&&storeType)?`${diffPcs?`(${pcsQty}`:''}${storeType}${diffPcs?')':''}`:''}/${unit}`, totalPrice.toFixed(2));
        }
      }
    }
    if (fullWidth === 48) {
      await rowGap(printer);
    }

    if (!hidePrice) {
      await divider(printer, fullWidth);
      await columnsWrite(printer, fullWidth, 'Subtotal', `${tagQuantity||calculatePcsQty(items, false, true)}/pcs`, `${subTotalPrice.toFixed(2)}`);
      const details = calculateGrandTotalDetails(order.data());
      if (details.length>0) {
        await newLine(printer);
        for (const {title, description, amount, discount, quantity} of details) {
          await columnsWrite(printer, fullWidth, `${title}${description?` (${description})`:''}`, `${quantity?`x${quantity}`:''}`, `${discount?'-':''}${amount.toFixed(2)}`);
        }
      }

      if (totalServiceFee) {
        await divider(printer, fullWidth);
        await columnsWrite(printer, fullWidth, `Grand Total`, `${(totalPrice-(totalServiceFee||0)).toFixed(2)}`);
        await newLine(printer);
        if (totalServiceFee) {
          await columnsWrite(printer, fullWidth, `Service Fee(${serviceFeeRate}%)`, totalServiceFee.toFixed(2));
        }
        // if (totalGST) {
        //   await columnsWrite(printer, fullWidth, `GST(${gstRate}%)`, totalGST.toFixed(2));
        // }
      }
    }

    if (!hidePrice||!internal||!hideTotalQty) {
      const totalQty = tagQuantity||calculatePcsQty(items, false, true, departmentFilter);
      const noAccerroryQty = tagQuantity||calculatePcsQty(items, false, false, departmentFilter);

      await divider(printer, fullWidth);
      await columnsWrite(printer, fullWidth, hidePrice?'Total Qty':'Total',
        hidePrice?`${(totalQty>noAccerroryQty)?`${noAccerroryQty} + (${parseInt(totalQty-noAccerroryQty)})`:totalQty}`:`${totalPrice.toFixed(2)}`);
    }

    if (!hidePrice&&paymentTracking) {
      await newLine(printer);
      let paidAmount = totalPaid;

      if (payments) {
        paidAmount = 0;
        for (const payment of payments) {
          const { type, amount, amountRefunded, status } = payment.data();
          if ((!amountRefunded)||(amountRefunded < amount)) {
            if ((status===PAYMENT_STATUS_PENDING)||(status===PAYMENT_STATUS_SUCCEED)) {
              await columnsWrite(printer, fullWidth, `${(status===PAYMENT_STATUS_PENDING)?`Pending payment`:`Payment received`} by ${type}${(amountRefunded&&(amountRefunded>0))?`($${amountRefunded} Refunded)`:``}`, amount.toFixed(2));
              paidAmount = formatPrice((paidAmount||0) + Math.max(0, amount - (amountRefunded||0)));
            }
          }
        }
      }

      await divider(printer, fullWidth);
      if ((paidAmount||0) < (totalPrice||0)) {
        await columnsWrite(printer, fullWidth, `Total Balance`, Math.max(0, totalPrice - (paidAmount||0)).toFixed(2));
      }
    }

    if (totalSurcharge&&hidePrice) {
      await newLine(printer);
      await columnsWrite(printer, fullWidth, 'Surcharge', `${totalSurcharge.toFixed(2)}`);
    }

    if (notes&&(internal||(notes.toLowerCase().indexOf('note:')>=0))) {
      await bold(printer, true);
      await newLine(printer);
      if (internal&&!largeFont) {
        await fontSize(printer, 2);
      }
      await write(printer, `${notes}`);
      if (internal&&!largeFont) {
        await fontSize(printer, 1);
      }
      await newLine(printer);
      await bold(printer, false);
    }

    if (!internal) {
      if (createSign&&createSign.image) {
        await image(printer, createSign.image, (fullWidth-8)*3);
      } else {
        await newLine(printer, 2);  
      }
      await write(printer, `Customer Signature: _________________`);
      await newLine(printer);
    }

    if (internal) {
      await box(printer, fullWidth);
    } else {
      await newLine(printer, 1);
    }

    await alignMiddle(printer);
    const codexContent = barcodeIndex?`${getDisplayId(order)}@${barcodeIndex}`:getDisplayId(order);
    if (fullWidth === 48) {
      await qrcode(printer, codexContent);
      await newLine(printer, 2);
    } else {
      await newLine(printer, 1);
    }
    await barcode(printer, codexContent, fullWidth===48);

    const termsItems = items&&items.filter(item=>item.terms);

    if (!internal&&(customerTerms||(termsItems&&(termsItems.length>0)))) {
      await newLine(printer, 2);
      await alignLeft(printer);
      await font(printer, true);
      if (customerTerms) {
        await write(printer, customerTerms);
      }
      if (termsItems&&(termsItems.length>0)) {
        if (customerTerms) {
          for (const { name, terms } of termsItems) {
            await newLine(printer, 1);
            await write(printer, name||'');
            await newLine(printer, 1);
            await write(printer, terms||'');
          }
        }
      }
      await font(printer, false);
    }
    await newLine(printer, 4);
    if (cutter) {
      await newLine(printer, 2);
      await cut(printer);
    }
    await reset(printer);
  } catch (err) {
    Modal.confirm({
      title: `Try restart and repair the printer.`,
      content: err.message
    });
  }
  return true;
}

export async function printPaperBarcodes(printer, order, times) {
  if (!printer) return toast(`Please pair your printer`);
  let fullWidth = 48, cutter = true;
  if (printer.service&&printer.service.device&&(printer.service.device.name==='DL581printer')) {
    fullWidth = 32;
    cutter = false;
  }

  if ((typeof times === 'number')&&(times > 0)) {
    const codexContent = getDisplayId(order);

    await reset(printer);
    await alignMiddle(printer);
    for (let index = 0; index < times; index++) {
      await qrcode(printer, codexContent);
      await newLine(printer, 2);
      await barcode(printer, codexContent, fullWidth===48);
      await fontSize(printer, 2);
      await alignRight(printer);
      await write(printer, `$${order.get('totalPrice')}`);
      await newLine(printer, 5);
      if (cutter) {
        await cut(printer);
      }
    }
  }
}

export async function printHandoverSlip(printer, slip) {
  if (!printer) return toast(`Please pair your printer`);
  let fullWidth = 48, cutter = true;
  if (printer.service&&printer.service.device&&(printer.service.device.name==='DL581printer')) {
    fullWidth = 32;
    cutter = false;
  }
  const { factory, business, from, to, orders, signature, handoverType } = slip.data();

  try {
    await reset(printer);
    await fontSize(printer, 2);
    await alignMiddle(printer);
    await newLine(printer, 2);
    await write(printer, `${handoverType||'ORDER'} HANDOVER`.toUpperCase());
    await newLine(printer, 2);
    await fontSize(printer, 1);
    await alignLeft(printer);
    await columnsWrite(printer, fullWidth, 'Factory:', factory.displayName||'', undefined, true);
    await columnsWrite(printer, fullWidth, 'Business:', business.displayName||'', undefined, true);
    if (from) {
      await columnsWrite(printer, fullWidth, 'Handover From:', from.displayName||'', undefined, true);
    }
    if (to) {
      await columnsWrite(printer, fullWidth, 'Handover To:', to.displayName||'', undefined, true);
    }
    await columnsWrite(printer, fullWidth, 'Create At:', moment().format('HH:mm DD/MM YYYY'), undefined, true);
    await newLine(printer);
    await columnsWrite(printer, fullWidth, 'Details', 'Order No', undefined, true);
    await divider(printer, fullWidth);
    await newLine(printer);
    for (const {id, description} of orders) {
      await columnsWrite(printer, fullWidth, `${description}`, `${id}`);
    }
    await divider(printer, fullWidth);
    await write(printer, `Total: ${orders.length} order${(orders.length>1)?'s':''}`);
    await newLine(printer, 2);
    if (signature) {
      await write(printer, `Signature:`);
      await newLine(printer);
      await image(printer, signature, (fullWidth-8)*3);
    }
    await newLine(printer, 4);
    if (cutter) {
      await cut(printer);
    }
  } catch (err) {
    Modal.confirm({
      title: `Try restart and repair the printer.`,
      content: err.message
    });
  }
}

export async function printCollectionSlip(printer, order, {brandUrl}) {
  if (!printer) {
    toast(`Please pair your printer at the top right of the home page`);
    return false;
  }
  const { business, items, customer, created, collectSign } = order.data();
  let fullWidth = 48, cutter = true;
  if (printer.service&&printer.service.device&&(printer.service.device.name==='DL581printer')) {
    fullWidth = 32;
    cutter = false;
  }

  try {
    await reset(printer);
    await fontSize(printer, 2);
    await alignMiddle(printer);
    await newLine(printer, 2);
    if (brandUrl) {
      await image(printer, brandUrl, (fullWidth-8)*6);
      await newLine(printer);
    }
    await write(printer, `${getDisplayId(order)} COLLECTION`);
    await newLine(printer, 2);
    await fontSize(printer, 1);
    await alignLeft(printer);
    await columnsWrite(printer, fullWidth, 'Business:', business.displayName||'', undefined, true);
    await columnsWrite(printer, fullWidth, 'Order ID:', getDisplayId(order), undefined, true);
    if (customer) {
      await columnsWrite(printer, fullWidth, 'Customer:', getCustomerDisplay(customer), undefined, true);
    }
    await columnsWrite(printer, fullWidth, 'Create Date:', created.short||'', undefined, true);
    await columnsWrite(printer, fullWidth, 'Collection Date:', moment().format('HH:mm DD/MM'), undefined, true)
    await newLine(printer, 2);
    await columnsWrite(printer, fullWidth, 'Item', 'Qty')
    await divider(printer, fullWidth);
    await newLine(printer);
    for (const {name, description, quantity, unit} of items) {
      await columnsWrite(printer, fullWidth, `${name} (${description})`, `x${quantity} ${unit}`);
    }
    await divider(printer, fullWidth);
    await columnsWrite(printer, fullWidth, 'Total', `${[0,...items.map(item=>item.quantity)].reduce((a,b)=>a+b)}`);
    if (collectSign) {
      await newLine(printer);
      await write(printer, `Collection Signature:`);
      await newLine(printer);
      await image(printer, collectSign.image, (fullWidth-8)*3);
    }
    await newLine(printer, 4);
    if (cutter) {
      await cut(printer);
    }
  } catch (err) {
    Modal.confirm({
      title: `Try restart and repair the printer.`,
      content: err.message
    });
  }

  return true;
}

export async function printDailyReport(printer, { displayName, datas, 
    operator, date }) {
  if (!printer) {
    toast(`Please pair your printer at the top right of the page`);
    return false;
  }

  let fullWidth = 48, cutter = true;
  if (printer.service&&printer.service.device&&(printer.service.device.name==='DL581printer')) {
    fullWidth = 32;
    cutter = false;
  }

  try {
    await reset(printer);
    await fontSize(printer, 3);
    await alignMiddle(printer);
    await write(printer, `${date.format('DD/MM YYYY')}`);
    await fontSize(printer, 2);
    await newLine(printer, 2);
    await write(printer, `${displayName}`);
    await newLine(printer, 2);
    await fontSize(printer, 1);
    await alignLeft(printer);
    await columnsWrite(printer, fullWidth, 'Report Date:', date.format('DD/MM YYYY'), undefined, true);
    await columnsWrite(printer, fullWidth, 'Create Time:', moment().format('HH:mm DD/MM YYYY'), undefined, true);
    await columnsWrite(printer, fullWidth, 'Create By:', operator.displayName, undefined, true)
    await newLine(printer, 2);
    for (let index = 0; index < datas.length; index++) {
      for (const {title, amount, middle, tag, isDivider} of datas[index]) {
        if (isDivider) {
          await divider(printer, fullWidth);
        } else {
          await columnsWrite(printer, fullWidth, `${title}`, tag?`${tag.title}`:(middle||``), `${(typeof amount === 'number')?amount.toFixed(2):amount}`);
        }
      }
      if (index < (datas.length-1)) {
        await newLine(printer, 1);
      }
    }

    await newLine(printer, 6);
    if (cutter) {
      try {
        await cut(printer);
      } catch (err) {

      }
    }
  } catch (err) {
    Modal.confirm({
      title: `Try restart and repair the printer.`,
      content: err.message
    });
  }

  return true;
}

export async function printCombineInvocie(printer, orders, {brandUrl, address, contactNo}) {
  if (!printer) {
    toast(`Please pair your printer at the top right of the page`);
    return false;
  }

  let lineItems = [];
  orders.forEach(order => {
    const items = order.get('items');
    items&&items.forEach(item => {
      let added = lineItems.find(add=>(add.productId===item.productId));
      if (added) {
        added.quantity = formatPrice(added.quantity + item.quantity);
        if (item.inputQty) {
          added.inputQty = (added.inputQty||0) + item.inputQty;
        } 
      } else {
        lineItems.push(item);
      }
    });
  });
  
  let fullWidth = 48, cutter = true;
  if (printer.service&&printer.service.device&&(printer.service.device.name==='DL581printer')) {
    fullWidth = 32;
    cutter = false;
  }

  try {
    await reset(printer);
    await alignMiddle(printer);
    if (brandUrl) {
      await image(printer, brandUrl, (fullWidth-8)*6);
    }
    await fontSize(printer, 1);
    await alignLeft(printer);
    if (address) {
      await columnsWrite(printer, fullWidth, 'Address:', `${address}`, undefined, true);
    }
    if (contactNo) {
      await columnsWrite(printer, fullWidth, 'Contact:', `${contactNo}`, undefined, true);
    }
    if (address||contactNo) {
      await newLine(printer, 1);  
    }
    await columnsWrite(printer, fullWidth, 'Customer:', `${orders[0].get('business').displayName}`, undefined, true);
    await columnsWrite(printer, fullWidth, 'Date:', moment().format('DD/MM YYYY'), undefined, true);
    await newLine(printer, 2);
    await columnsWrite(printer, fullWidth, 'Item', 'Qty')
    await divider(printer, fullWidth);
    for (const item of lineItems) {
      await columnsWrite(printer, fullWidth, `${item.name}${item.inputQty?` (${item.inputQty} pcs)`:''}`, `${item.quantity}/${item.unit}`);
    }

    await newLine(printer, 2);
    await box(printer, fullWidth);

    await newLine(printer, 6);
    if (cutter) {
      try {
        await cut(printer);
      } catch (err) {
      }
    }
  } catch (err) {
    Modal.confirm({
      title: `Try restart and repair the printer.`,
      content: err.message
    });
  }

  return true;
}

export async function printCheckInOutSlip(printer, { handoverType, businessName, orders, 
  operator, date }) {
if (!printer) {
  toast(`Please pair your printer at the top right of the page`);
  return false;
}

let fullWidth = 48, cutter = true;
if (printer.service&&printer.service.device&&(printer.service.device.name==='DL581printer')) {
  fullWidth = 32;
  cutter = false;
}

try {
  await reset(printer);
  await fontSize(printer, 2);
  await alignMiddle(printer);
  await write(printer, handoverType.title);
  await fontSize(printer, 2);
  await newLine(printer, 2);
  await write(printer, `${businessName}`);
  await newLine(printer, 2);
  await fontSize(printer, 1);
  await alignLeft(printer);
  await columnsWrite(printer, fullWidth, 'Date:', date.format('DD/MM YYYY'), undefined, true);
  await columnsWrite(printer, fullWidth, 'Orders:', `${orders.length}`, undefined, true);
  await columnsWrite(printer, fullWidth, 'Create Time:', moment().format('HH:mm DD/MM YYYY'), undefined, true);
  await columnsWrite(printer, fullWidth, 'Create By:', operator.displayName, undefined, true);
  await newLine(printer, 2);
  await alignMiddle(printer);
  for (const order of orders) {
    const displayId = getDisplayId(order);
    // await alignLeft(printer);
    // await write(printer, displayId);
    await barcode(printer, displayId);
  }

  await newLine(printer, 6);
  if (cutter) {
    try {
      await cut(printer);
    } catch (err) {

    }
  }
} catch (err) {
  Modal.confirm({
    title: `Try restart and repair the printer.`,
    content: err.message
  });
}

return true;
}