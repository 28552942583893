import React from 'react';

export const AppContext = React.createContext();

export function statusColor(task) {
  if (task.get('cancelled')) {
    return '9E9E9E';
  } else if (task.get('failed')) {
    return 'F44336';
  } else if (task.get('completed')) {
    return '4CAF50';
  } else if (task.get('worker')) {
    return 'FF9800';
  } else {
    return 'FDD835';
  }
}

export function statusName(task) {
  if (task.get('cancelled')) {
    return 'Cancelled';
  } else if (task.get('failed')) {
    return 'Failed';
  } else if (task.get('completed')) {
    return 'Completed';
  } else if (task.get('worker')) {
    return 'Assigned';
  } else {
    return 'Pending';
  }
}

export const placeholders = [
  'customer_name',
  'task_description',
  'task_date',
  'task_address',
  'worker_name',
  'worker_contact',
  'customer_service_contact',
  'business_brand_name',
  'admin_short_link',
  'worker_input_failed_reason'
];