const iconv = require('iconv-lite');
const Canvas = require('canvas-browserify');
const Dither = require('canvas-dither');
const Flatten = require('canvas-flatten');
const EscPosEncoder = require('esc-pos-encoder');

const ESC = 0x1B;
const RESET = 0x40;
const CMD = 0x1d;
const ALIGN = 0x61;
const FONT_SIZE = 0x21;
const BARC0DE = 0x6b;
const HEIGHT = 0x68;
const WDITH = 0x77;
const CUT = 0x56;
const ALL = 0x00;
const HALF = 0x01;
const HRI = 0x48;
const BEEP = 0x42;
const BELOW = 0x02;
const CODE_128 = 0x49;
const CODE_39 = 0x04;
const CODE_128_SET_C = [0x7B, 0x43];
const NEW_LINE = 0x0A;
const BOLD = 0x45;
const YES = 0x01;
const NO = 0x00;

export async function reset(printer) {
  await write(printer, new Uint8Array([ESC, RESET]));
}

export async function loadImage(dataURL) {
  return new Promise(async (resolve) => {
    if (dataURL == null) return resolve();

    let image = new Image();
    image.addEventListener('load', function() {
      resolve(image);
    });
    image.src = dataURL;
  });
}

export async function resizeImage(dataURL, maxWidth, format) {
  const img = await loadImage(dataURL);

  var canvas = document.createElement("canvas");
  var ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0);

  var width = img.width;
  var height = img.height;

  if (width>maxWidth) {
    width = maxWidth;
    height = img.height / img.width * width;
  }

  canvas.width = width;
  canvas.height = height;
  var ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, width, height);
  return canvas.toDataURL(format||'image/png');
}

// export async function image(printer, dataURL, targetWidth=120) {
//   const img = await loadImage(dataURL);
//   var width = targetWidth;
//   width =  width - (width % 8);
//   var height = parseInt(img.height / img.width * width);
//   height =  height - (height % 8);

//   const posEncoder = new EscPosEncoder();
//   const imageBuffer = posEncoder
//     .image(img, width, height, 'atkinson')
//     .encode()
//   await write(printer, imageBuffer);
// }

export async function image(printer, dataURL, targetWidth=120){
  const img = await loadImage(dataURL);
  const algorithm = 'atkinson';
  var width = targetWidth;
  width =  width - (width % 8);
  var height = parseInt(img.height / img.width * width);
  height =  height - (height % 8);

  let canvas = new Canvas(width, height);
  let context = canvas.getContext('2d');
  context.drawImage(img, 0, 0, width, height);
  let image = context.getImageData(0, 0, width, height);

  image = Flatten.flatten(image, [0xff, 0xff, 0xff]);

  switch (algorithm) {
      case 'floydsteinberg': image = Dither.floydsteinberg(image); break;
      case 'atkinson': image = Dither.atkinson(image); break;
  }

  let getPixel = (x, y) => image.data[((width * y) + x) * 4] > 0 ? 0 : 1;

  let bytes = new Uint8Array((width * height) >> 3);

  for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x = x + 8) {
          let i = (y * (width >> 3)) + (x >> 3);
          bytes[i] =
              getPixel(x + 0, y) << 7 |
              getPixel(x + 1, y) << 6 |
              getPixel(x + 2, y) << 5 |
              getPixel(x + 3, y) << 4 |
              getPixel(x + 4, y) << 3 |
              getPixel(x + 5, y) << 2 |
              getPixel(x + 6, y) << 1 |
              getPixel(x + 7, y);
      }
  }

  await write(printer, new Uint8Array ([0x1d, 0x76, 0x30, 51]));
  await write(printer, new Uint8Array ([(width >> 3) & 0xff, (((width >> 3) >> 8) & 0xff)]));
  await write(printer, new Uint8Array ([height & 0xff, ((height >> 8) & 0xff)]));
  await write(printer, bytes);
}

export async function bold(printer, bold) {
  await write(printer, new Uint8Array([ESC, BOLD, bold?YES:NO]));
}

export async function font(printer, small) {
  await write(printer, new Uint8Array([ESC, 0x4D, small?YES:NO]));
}

export async function charset(printer, chinese) {
  await write(printer, new Uint8Array([ESC, 0x52, chinese?15:0]));
}

export async function chineseCharset(printer) {
  await write(printer, new Uint8Array([0x1C, 0x26]));
}

export async function fontSize(printer, n=1) {
  let times;
  switch(n) {
    case 1: times = 0x00; break;
    case 2: times = 0x11; break;
    case 3: times = 0x22; break;
    case 4: times = 0x33; break;
    case 5: times = 0x44; break;
    case 6: times = 0x55; break;
    case 7: times = 0x66; break;
    case 8: times = 0x77; break;
  }
  await write(printer, new Uint8Array([CMD, FONT_SIZE, times]));
}

export async function alignLeft(printer) {
  await write(printer, new Uint8Array([ESC, ALIGN, 0x00]));
}

export async function alignMiddle(printer) {
  await write(printer, new Uint8Array([ESC, ALIGN, 0x01]));
}

export async function alignRight(printer) {
  await write(printer, new Uint8Array([ESC, ALIGN, 0x02]));
}

export async function rowGap(printer, value) {
  if (value) {
    await write(printer, new Uint8Array([ESC, 0x33, value||0]));
  } else {
    await write(printer, new Uint8Array([ESC, 0x32]));
  }
}

export async function newLine(printer, num=1) {
  await write(printer, new Uint8Array(Array.apply(null, Array(num)).map(() => NEW_LINE)))
}

export async function reverse(printer, isReverse) {
  await write(printer, new Uint8Array([CMD, 0x42, isReverse?0x01:0x00]));
}

export async function write(printer, content, continues) {
  if (typeof content === 'string') {
    content = iconv.encode(content, 'GB18030');
  }

  if (printer.transferOut) {
    await printer.transferOut(2, content);
  } else {
    let index = 0;
    while(content.slice(index, index+20).length) {
      await printer.writeValue(content.slice(index, index+20));
      index += 20;
      if (!continues) {
        await new Promise((resolve) => {
          setTimeout(() => resolve(), 2);
        });
      }
    }
  }
}

export async function qrcode(printer, content) {
  const posEncoder = new EscPosEncoder();
  const imageBuffer = posEncoder
    .qrcode(content, undefined, 4)
    .encode()
  await write(printer, imageBuffer);
}

export async function barcode(printer, content, newCode) {
  await write(printer, new Uint8Array([CMD, HEIGHT, 60]));
  await write(printer, new Uint8Array([CMD, WDITH, 2]));
  await write(printer, new Uint8Array([CMD, HRI, BELOW]));
  if (newCode) {
    await write(printer, new Uint8Array([CMD, BARC0DE, CODE_128, content.length+2, 0x7B, 0x42]));
    await write(printer, content);
  } else {
    await write(printer, new Uint8Array([CMD, BARC0DE, CODE_39]));
    await write(printer, content);
    await write(printer, new Uint8Array([0x00]));
  }
}

export async function cut(printer, all) {
  await write(printer, new Uint8Array([CMD, CUT, all?ALL:HALF]));
}

export async function beep(printer, n=1, t=1) {
  await write(printer, new Uint8Array([CMD, BEEP, n, t]));
}

export async function divider(printer, length=32) {
  await write(printer, `${Array.apply(null, Array(length)).map(() => '-').join('')}\n`);
}

export async function box(printer, width=32) {
  await newLine(printer);
  await divider(printer, width);
  for (let i = 0; i< 3; i++) {
    await write(printer, `|${Array.apply(null, Array(width-1)).map(() => '').join(' ')}|`);
    await newLine(printer);
  }
  await divider(printer, width);
}

function spaces(length) {
  if (length<0) return '';
  return Array(Math.max(0, length)).fill(' ').join('');
}

export async function columnsWrite(printer, lineLength=32, column1, column2, column3) {
  column1 = `${column1}  `;
  let totalComuns, print1Width = 0, print2Width = 0, print3Width = 0;
  if (column3 !== undefined) {
    totalComuns = 3;
    column2 = `${spaces(9-(column2||'').length)}${column2||''}`;
    column3 = `${spaces(7-(column3||'').length)}${column3||''}`;
    print3Width = column3.length;
    print2Width = column2.length;
    print1Width = lineLength-print3Width-print2Width;
  } else if (column2 !== undefined) {
    totalComuns = 2;
    column2 = `${spaces(7-(column2||'').length)}${column2||''}`;

    if (column1.length> column2.length) {
      print2Width = column2.length;
      print1Width = lineLength-print2Width;
    } else {
      print1Width = column1.length;
      print2Width = lineLength - print1Width;
    }
  } else {
    totalComuns = 1;
    print1Width = lineLength;
  }

  while(column1||column2||column3) {
    let print1, print2, print3;
    if (column1) {
      print1 = column1.slice(0, print1Width);
      column1 = column1.slice(print1Width);
    }
    if (column2) {
      if (column2.split('\n').length>1) {
        const splits = column2.split('\n');
        print2 = splits[0];
        column2 = splits.slice(1).join('\n');
      } else {
        print2 = column2.slice(0, print2Width);
        column2 = column2.slice(print2Width);
      }
    }
    if (column3) {
      print3 = column3.slice(0, print3Width);
      column3 = column3.slice(print3Width);
    }

    await write(printer, `${print1||''}${spaces(print1Width-(print1?print1.length:0))}${(totalComuns>1)?`${spaces(print2Width-(print2?print2.length:0))}${print2||''}`:''}${(totalComuns>2)?`${spaces(print3Width-(print3?print3.length:0))}${print3||''}`:''}`);
    await newLine(printer);
  }
}