import React, { Component } from 'react';
import { Alert, Button } from 'antd';
import { AppContext } from 'context';

class ErrorBoundary extends Component {
  state = {}
  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ error, info });
    this.props.firebase.firestore().collection('errors').add({
      date: new Date(),
      ...(error.message&&{message: error.message}),
      ...(error.stack&&{stack: error.stack}),
      ...(this.props.account&&{
        user: {
          id: this.props.account.uid,
          ...(this.props.account.displayName&&{
            displayName: this.props.account.displayName
          })
        }
      })
    });
  }
  onRecover = () => window.location.reload(true)
  onReset = () => {
    this.props.firebase.auth().signOut();
  }
  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      return (
        <div className='flex padding'>
          <Alert key='error' type='error' showIcon message={this.state.error.message}
            description={this.state.info&&this.state.info.componentStack}/>
          <br/>
          <Alert key='info' type='info' showIcon
            message={`We already received this error, we will fix it ASAP.`} />
          <br/>
          <Button size='large' type='primary' icon='medicine-box' onClick={this.onRecover}>RECOVER</Button>
          <br/>
          <Button size='large' type='danger' icon='logout' onClick={this.onReset}>LOGOUT & RESET</Button>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}

export default (props) => (
  <AppContext.Consumer>
    {state => <ErrorBoundary {...props} {...state}/>}
  </AppContext.Consumer>
)