import React, { Component } from 'react';
import { Spin, Modal, Alert } from 'antd';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

export default class Loading extends Component {
  componentDidUpdate(prevProps) {
    if (!prevProps.error&&this.props.error) {
      Modal.confirm({
        title: `New version available`,
        content: <Alert type='info' message={`Please wait for around 5 seconds, then click the refresh button below!`}/>,
        okText: `REFRESH`,
        okCancel: false,
        onOk: () => window.location.reload(true)
      });
    }
  }
  render() {
    return (
      <div>
        {!this.props.hideBar&&<AppBar style={{zIndex:99}} position="fixed" color='default'>
          <Toolbar>
            <Typography style={{flex:1}} variant="title" color="inherit">
              Loading...
            </Typography>
          </Toolbar>
        </AppBar>}
        <Spin><div style={{height:'100vh'}}/></Spin>
      </div>
    );
  }
}