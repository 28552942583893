import React from 'react';
import { message } from 'antd';
import moment from 'moment';
import escapeStringRegexp from 'escape-string-regexp';
import ClientJS from 'clientjs';
import { calculateOrderPrice, formatPrice, PERCENT_SURCHARGE, FLAT_SURCHARGE, PERCENT_DISCOUNT, FLAT_DISCOUNT } from './shares';
import { calculatePcsQty } from './printerUtils';
import { TYPE_PERCENTAGE, HONESTBEE_BUS_NAME } from './constants';
import XLSX from 'xlsx';

export const APP_VERSION = '1.13.93';

export const LABEL_WIDTH_NARROW = 8;
export const LABEL_WIDTH_STSANDARD = 10;

export const PAYMENT_METHOD_CREDITCARD = {
  label: 'Credit Card',
  value: 'creditcard'
};
export const PAYMENT_METHOD_CASH = {
  label: 'Cash',
  value: 'cash'
};

export const ORDER_ID_PLACEHOLDERS = ['YY','MM','DD','DAY_SEQUENCE','MONTH_SEQUENCE','DAY_SEQUENCE'];
export const QC_PLACEHOLDERS = [
  'ORDER_NO',
  'TRACKING_ID',
  'CUSTOMER_NAME',
  'BUSINESS_NAME',
  'CUSTOMER_CONTACT',
  'QC_ISSUES',
  'QC_ITEM',
  'QC_SUGGESTION',
];
export const LABEL_PLACEHOLDERS = ['ORDER_NO', 'TOTAL_QUANTITY',
  'BUSINESS_NAME', 'DELIVERY_DATE', 'WASH_TYPE_CODE', 'WASH_TYPE_QUANTITY', 'ORDER_TAG_NOTE', 'CREATION_DAY',
  'COLLECTION_STORAGE', 'STORAGE_TYPES', 'ADDON_SERVICES'];

export const INVOICE_NO_PLACE_PLACEHOLDERS = ['YEAR','MONTH','DATE','COUNTER'];

export const ACCESSORY_MARKS = ['#', '*', '&', '@', '%', '$', '^'];

export const LINK_ACCOUNT_QUICKBOOKS = 'Quickbooks';

export const SETTLEMENT_DATE_CREATION = 'created';
export const SETTLEMENT_DATE_PICKUP = 'pickup';
export const SETTLEMENT_DATE_DELIVERY = 'delivery';

export const PAYMENT_TYPE_CASH = 'CASH';
export const PAYMENT_TYPE_NETS = 'NETS';
export const PAYMENT_TYPE_CHEQUE = 'CHEQUE';
export const PAYMENT_TYPE_CREDITCARD = 'CREDITCARD';
export const PAYMENT_TYPE_BANKTRANSFER = 'TRANSFER';
export const PAYMENT_TYPE_PAYNOW = 'PAYNOW';
export const PAYMENT_TYPE_MONTH_BILL = 'MONTHBILL';
export const PAYMENT_TYPE_STRIPE = 'STRIPE';
export const PAYMENT_TYPE_PAYPAL = 'PAYPAL';
export const PAYMENT_TYPE_WAIVE = 'WAIVE';
export const PAYMENT_TYPE_CREDIT = 'CREDIT';

export const LINE_ITEMS_BY_ITEM = 'byItem';
export const LINE_ITEMS_BY_ORDER = 'byOrder';
export const LINE_ITEMS_BY_ORDER_WITH_ITEM = 'byOrderWithItem';

export const SORT_BY_ORDER_ID = 'byOrderId';
export const SORT_BY_CONTINUES_ORDER_ID = 'byOrderIdContinus';
export const SORT_BY_DATE = 'byDate';

export const INVOICE_TYPE_BY_ORDER = 'BY_ORDER';
export const INVOICE_TYPE_MONTHLY_BILL = 'MONTHLY_BILL';

export const BASE_SYSTEM_ID = 'BASE_SYSTEM';
export const TAGGING_SYSTEM_ID = 'TAGGING_SYSTEM';
export const COLLECTION_POINT_SYSTEM_ID = 'COLLECTION_POINT_SYSTEM';
export const INVOICING_SYSTEM_ID = 'INVOICING_SYSTEM';
export const LOGISTIC_SYSTEM_ID = 'LOGISTIC_SYSTEM';
export const BARCODE_SYSTEM_ID = 'BARCODE_SYSTEM';
export const WEB_ORDERING_SYSTEM_ID = 'WEB_ORDERING_SYSTEM';
export const HOTEL_SYSTEM_ID = 'HOTEL_SYSTEM';

export const PAYMENT_STATUS_PENDING = 'pending';
export const PAYMENT_STATUS_SUCCEED = 'succeed';
export const PAYMENT_STATUS_FAILED = 'failed';

export const SAAS_SUBSYSTEMS = [
  BASE_SYSTEM_ID,
  HOTEL_SYSTEM_ID,
  TAGGING_SYSTEM_ID,
  COLLECTION_POINT_SYSTEM_ID,
  INVOICING_SYSTEM_ID,
  LOGISTIC_SYSTEM_ID,
  BARCODE_SYSTEM_ID,
  WEB_ORDERING_SYSTEM_ID
];

export const paymentColors = {
  [PAYMENT_TYPE_CASH]: '#4CAF50',
  [PAYMENT_TYPE_NETS]: '#9C27B0',
  [PAYMENT_TYPE_CREDITCARD]: '#673AB7',
  [PAYMENT_TYPE_BANKTRANSFER]: '#3F51B5',
  [PAYMENT_TYPE_PAYNOW]: '#2196F3',
  [PAYMENT_TYPE_CREDIT]: '#2196F3',
  [PAYMENT_TYPE_MONTH_BILL]: '#00BCD4',
  [PAYMENT_TYPE_STRIPE]: '#009688',
  [PAYMENT_TYPE_PAYPAL]: '#FF9800',
  [PAYMENT_TYPE_CHEQUE]: '#795548'
};

export function isValidEmail(emailAddress) {
  var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
  return pattern.test(emailAddress);
};

export function formatPaymentFigures(payments, refunds) {
  let paymentMaps = {}, paymentTotals = {}, refundedTotals = {}, clearedTotals = {};
  payments&&payments.forEach(payment => {
    const { type, status } = payment.data();
    if ((status===PAYMENT_STATUS_SUCCEED)||(status===PAYMENT_STATUS_PENDING)) {
      if (paymentMaps[type]) {
        paymentMaps[type].push(payment);
      } else {
        paymentMaps[type] = [payment];
      }

      paymentTotals[type] = formatPrice((paymentTotals[type]||0) + payment.get('amount'));
      const { amountCleared, amountRefunded } = payment.data();
      if (amountCleared) {
        clearedTotals[type] = formatPrice((clearedTotals[type]||0) + amountCleared);
      }
      if (amountRefunded) {
        refundedTotals[type] = formatPrice((refundedTotals[type]||0) + amountRefunded);
      }
    }
  });
  refunds&&refunds.forEach(refund => {
    if (!paymentMaps[refund.type]) {
      paymentMaps[refund.type] = [];
      paymentTotals[refund.type] = 0;
    }
  });

  return { paymentMaps, paymentTotals, refundedTotals, clearedTotals };
}

export function getProductPath(busDoc) {
  if (busDoc.get('owner')) {
    if (`${busDoc.get('displayName')}`.toUpperCase()===HONESTBEE_BUS_NAME.toUpperCase()) {
      return `honestbee/${busDoc.get('owner').id}/products`;
    } else if (busDoc.get('itemGroup')) {
      return `factories/${busDoc.get('owner').id}/itemizations/${busDoc.get('itemGroup')}/products`;
    } else {
      return `factories/${busDoc.get('owner').id}/products`;
    }
  } else {
    return `businesses/${busDoc.id}/products`;
  }
}

function linenItemToDisplayArray(product, pickup, deliver) {
  return [
    ...(((typeof (pickup&&pickup.qty) === 'number')||(typeof (deliver&&deliver.qty) === 'number'))?[
      {name: `${product.name}${product.nameCn?` (${product.nameCn})`:''}`, ...(pickup&&(typeof pickup.qty === 'number')&&{pickup: pickup.qty}), ...(deliver&&(typeof deliver.qty === 'number')&&{deliver: deliver.qty}) }
    ]:[]),
    ...(((typeof (pickup&&pickup.rewash) === 'number')||(typeof (deliver&&deliver.rewash) === 'number'))?[
      {name: `${product.name}${product.nameCn?` (${product.nameCn})`:''}`, type: 'REWASH', ...(pickup&&(typeof pickup.rewash === 'number')&&{pickup: pickup.rewash}), ...(deliver&&(typeof deliver.rewash === 'number')&&{deliver: deliver.rewash}) }
    ]:[]),
    ...(((typeof (pickup&&pickup.special) === 'number')||(typeof (deliver&&deliver.special) === 'number'))?[
      {name: `${product.name}${product.nameCn?` (${product.nameCn})`:''}`, type: 'SPECIAL', ...(pickup&&(typeof pickup.special === 'number')&&{pickup: pickup.special}), ...(deliver&&(typeof deliver.special === 'number')&&{deliver: deliver.special}) }
    ]:[]),
    ...(((typeof (pickup&&pickup.newitem) === 'number')||(typeof (deliver&&deliver.newitem) === 'number'))?[
      {name: `${product.name}${product.nameCn?` (${product.nameCn})`:''}`, type: 'NEW', ...(pickup&&(typeof pickup.newitem === 'number')&&{pickup: pickup.newitem}), ...(deliver&&(typeof deliver.newitem === 'number')&&{deliver: deliver.newitem}) }
    ]:[])
  ];
}

export function toLinenDisplayItems(pickups, deliveries) {
  let dataSource = [].concat.apply([], (pickups||[]).map(pickup => {
    const deliver = deliveries&&deliveries.find(deliver=>deliver.id===pickup.id);
    return linenItemToDisplayArray(pickup, pickup, deliver);
  }));

  let missingDelivers = deliveries&&deliveries.filter(deliver=>!pickups||!pickups.find(pickup => pickup.id===deliver.id));
  if (missingDelivers&&(missingDelivers.length > 0)) {
    dataSource = [...dataSource, ...[].concat.apply([], missingDelivers.map(deliver => {
      return linenItemToDisplayArray(deliver, null, deliver);
    }))];
  }
  return dataSource;
}

export function renderQCMessages(qc, templates) {
  const { orderDisplayId, customer, suggestion, item, notes, instruction,
    trackingId, rejectItems, business } = qc.data();
  const template = templates&&templates.find(template=>template.id===instruction);
  let templatePlaceholder = template&&template.get('template');

  if (templatePlaceholder) {
    let placeholder, protection=20;
    do {
      protection--;
      placeholder = QC_PLACEHOLDERS.find(item => templatePlaceholder.indexOf(`@${item}`)>=0);
  
      if (placeholder) {
        let replacement = '';
        switch(placeholder) {
          case 'ORDER_NO':
            replacement = orderDisplayId||'';
            break;
          case 'TRACKING_ID':
            replacement = trackingId||'';
            break;
          case 'BUSINESS_NAME':
            replacement = (business&&business.displayName)||'';
            break;
          case 'CUSTOMER_NAME':
            replacement = (customer&&customer.displayName)||'';
            break;
          case 'CUSTOMER_CONTACT':
            replacement = (customer&&customer.phoneNumber)||'';
            break;
          case 'QC_ISSUES': {
            replacement = notes?notes.map(note=>note.notes).join('\r\n'):''
            if (rejectItems&&(rejectItems.length>0)) {
              replacement = `${replacement}\r\n\r\nReject Items:\r\n${rejectItems.map(({name, subType, reject, rejectUnitQty, unit})=>`${name}${subType?`(${subType})`:''} x ${rejectUnitQty?`${rejectUnitQty}${unit}${reject?`(${reject} pcs)`:''}`:`${reject} ${unit}`}`).join('\r\n')}`;
            }
            break;
          }
          case 'QC_ITEM': {
            replacement = item?`${item.name}`:'';
            break;
          }
          case 'QC_SUGGESTION': {
            replacement = suggestion?`We suggest: ${suggestion.notes}`:'';
            break;
          }
        }
    
        const params = templatePlaceholder.match(new RegExp(`@${placeholder}=\{(.*?)\}`, 'i'));
        let regHolder;
        if (params) {
          regHolder = params[0];
          if (params[1]!==replacement) {
            replacement = '';
          }
        } else {
          regHolder = `@${placeholder}`;
        }

        templatePlaceholder = templatePlaceholder.replace(new RegExp(regHolder, 'g'), replacement||'');
      }
    } while(placeholder&&(protection>0));

    return templatePlaceholder;
  } else {
    return `${orderDisplayId}${customer?` ${getCustomerDisplay(customer, true)}`:''}: ${notes?notes.map(note=>note.notes).join(', '):''}`;
  }
}

export function speakOut(message) {
  if ('speechSynthesis' in window) {
    var msg = new SpeechSynthesisUtterance(message);
    window.speechSynthesis.speak(msg);
  }
}

export async function getFactorySettings(firebase, businessId, factoryId) {
  return firebase.firestore().doc(`businesses/${businessId}/factorysettings/${factoryId}`).get();
}

export function getCustomerDisplay(customer, includePhone) {
  if (customer) {
    const { phoneNumber, title, displayName } = customer;
    return `${(title&&displayName)?`${title}`:''}${displayName?`${title?` `:''}${displayName}`:''}${(includePhone&&phoneNumber&&(phoneNumber!==displayName))?`${displayName?` (`:''}${phoneNumber}${displayName?`)`:''}`:''}`;
  } else {
    return '';
  }
}

export async function applyPromotion(firebase, businessId, order, account, code) {
  const promoCode = await firebase.firestore().doc(`businesses/${businessId}/promocodes/${code}`).get();
  if (!promoCode.exists) throw Error(`Promo code ${code} not found!`);

  const { start, end, minimum, cap, value, type, timesLimit, totalLimit, totalUsed } = promoCode.data();
  const startDate = moment.unix(start.unix.seconds);
  const endDate = moment.unix(end.unix.seconds);
  if (moment().isBefore(startDate)) {
    throw Error(`Promotion only start from ${startDate.format('MMM Do YYYY')}!`);
  }
  if (moment().isAfter(endDate)) {
    throw Error(`Promotion already expired at ${endDate.format('MMM Do YYYY')}!`);
  }
  if (minimum&&(calculateOrderPrice(order).totalPrice<minimum)) {
    throw Error(`Minimum order of ${minimum} required!`);
  }
  if (totalLimit&&totalUsed&&(totalUsed>=totalLimit)) {
    throw Error(`Promo code already reach the maximum usage limit!`);
  }
  if (timesLimit) {
    const usages = await firebase.firestore().collection(`businesses/${businessId}/promocodes/${code}/usages`)
      .where('customer.id', '==', account.uid).get();
    if (usages.size>=timesLimit) {
      throw Error(`This promo code only can be use ${timesLimit} times!`);
    }
  }

  return {
    code: promoCode.id,
    ...(minimum&&{minimum}),
    ...(cap&&{cap}),
    ...(value&&{value}),
    ...(type&&{type})
  };
}

export function roughSizeOfObject( object ) {

  var objectList = [];
  var stack = [ object ];
  var bytes = 0;

  while ( stack.length ) {
      var value = stack.pop();

      if ( typeof value === 'boolean' ) {
          bytes += 4;
      }
      else if ( typeof value === 'string' ) {
          bytes += value.length * 2;
      }
      else if ( typeof value === 'number' ) {
          bytes += 8;
      }
      else if
      (
          typeof value === 'object'
          && objectList.indexOf( value ) === -1
      )
      {
          objectList.push( value );

          for( var i in value ) {
              stack.push( value[ i ] );
          }
      }
  }
  return bytes;
}

export function isSameItem(item1, item2) {
  if (!item1||!item2) return false;

  return (item1.pricingId===item2.pricingId)
    &&(item1.productId===item2.productId)
    &&((item1.subType||'')===(item2.subType||''));
}

export function newCharge({business, orderId, orderDisplayId, type, amount,
  account, description, notes, token, createdAt, payDate, items}) {
  const commissionRate = getOrderCommissionRate(items);

  let capturer, status;
  switch(type) {
    case PAYMENT_TYPE_CASH:
    case PAYMENT_TYPE_CREDITCARD:
    case PAYMENT_TYPE_NETS:
    case PAYMENT_TYPE_WAIVE:
    case PAYMENT_TYPE_PAYNOW: {
      status = PAYMENT_STATUS_SUCCEED;
      capturer = getOperator(account);
      break;
    }
    case PAYMENT_TYPE_BANKTRANSFER:
    case PAYMENT_TYPE_STRIPE:
    case PAYMENT_TYPE_CHEQUE:
    case PAYMENT_TYPE_CREDIT:
    case PAYMENT_TYPE_PAYPAL: {
      status = PAYMENT_STATUS_PENDING;
      break;
    }
    default:
      throw Error(`Payment type ${type} not supported!`);
  }

  return {
    business,
    orderId,
    orderDisplayId,
    type,
    status,
    amount,
    createdAt: toFormattedDate(payDate||moment(createdAt)),
    creator: getOperator(account),
    ...((typeof commissionRate === 'number')&&{commissionRate}),
    ...(token&&{token}),
    ...(description&&{description}),
    ...(notes&&{notes}),
    ...(capturer&&{
      capturer,
      captureAt: toFormattedDate(payDate||moment(createdAt))
    })
  };
}

export function newRefund(charge, amount, description, account) {
  let status;
  switch(charge.get('type')) {
    case PAYMENT_TYPE_CASH:
    case PAYMENT_TYPE_CREDITCARD:
    case PAYMENT_TYPE_NETS:
    case PAYMENT_TYPE_PAYNOW: {
      status = PAYMENT_STATUS_SUCCEED;
      break;
    }
    case PAYMENT_TYPE_BANKTRANSFER:
    case PAYMENT_TYPE_STRIPE:
    case PAYMENT_TYPE_PAYPAL: {
      status = PAYMENT_STATUS_PENDING;
      break;
    }
    default:
      throw Error(`Payment type ${charge.get('type')} not supported!`);
  }

  return {
    status,
    amount,
    description,
    createdAt: toFormattedDate(moment()),
    creator: getOperator(account)
  };
}

// export const ORDER_STATUS_PENDING_ORDER_CONFIRM = {
//   stage: 0,
//   title: 'Pending Worker',
//   titleCn: '等待取件',
//   color: `#FF5722`
// };
// export const ORDER_STATUS_PENDING_ORDER_CONFIRM = {
//   stage: 1,
//   title: 'Pending Order Confirm',
//   titleCn: '等待订单确认',
//   color: `#FF5722`
// };
export const ORDER_STATUS_PENDING_PICKUP = {
  stage: 2,
  title: 'Pending Pickup',
  titleCn: '等待取件',
  color: `#FF9800`
};
export const ORDER_STATUS_PENDING_FACTORY_RECEIVE = {
  stage: 3,
  title: 'Pending Factory Receive',
  titleCn: '等待工厂接收',
  color: `#FFC107`
};
export const ORDER_STATUS_LAUNDRY_IN_PROGRESS = {
  stage: 4,
  title: 'Laundry in Progress',
  titleCn: '清洗中',
  color: `#CDDC39`
};
export const ORDER_STATUS_PENDING_DELIVERY_COLLECTION = {
  stage: 5,
  title: 'Pending Delivery Collection',
  titleCn: '等待送还',
  color: `#8BC34A`
};
export const ORDER_STATUS_PENDING_SHOP_RECEIVE = {
  stage: 6,
  title: 'Pending Shop Receive',
  titleCn: '等待商户接收',
  color: `#8BC34A`
};
export const ORDER_STATUS_PENDING_CUSTOMER_RECEIVE = {
  stage: 7,
  title: 'Pending Customer Receive',
  titleCn: '等待客户接收',
  color: `#8BC34A`
};
export const ORDER_STATUS_COMPLETED = {
  stage: 8,
  title: 'Completed',
  titleCn: '订单完成',
  color: `#4CAF50`
};

export const ORDER_STAGES = [
  null,
  null,
  ORDER_STATUS_PENDING_PICKUP,
  ORDER_STATUS_PENDING_FACTORY_RECEIVE,
  ORDER_STATUS_LAUNDRY_IN_PROGRESS,
  ORDER_STATUS_PENDING_DELIVERY_COLLECTION,
  ORDER_STATUS_PENDING_SHOP_RECEIVE,
  ORDER_STATUS_PENDING_CUSTOMER_RECEIVE,
  ORDER_STATUS_COMPLETED
];

export function getStageOperator(stage) {
  switch(stage) {
    case ORDER_STATUS_PENDING_FACTORY_RECEIVE.stage: return 'shopOuter';
    case ORDER_STATUS_LAUNDRY_IN_PROGRESS.stage: return 'checkIner';
    case ORDER_STATUS_PENDING_SHOP_RECEIVE.stage: return 'checkOuter';
    case ORDER_STATUS_PENDING_CUSTOMER_RECEIVE.stage: return 'shopIner';
    case ORDER_STATUS_COMPLETED.stage: return 'completer';
  }
}

export const HANDOVER_TYPE_SHOP_CHECKOUT = {
  diffDays: 0,
  title: 'Shop Pickup',
  titleCn: '出店交接',
  newStatus: ORDER_STATUS_PENDING_FACTORY_RECEIVE,
  dateKey: 'pickup',
  operatorKey: 'shopOuter'
};

export const HANDOVER_TYPE_FACTORY_CHECKIN = {
  diffDays: 0,
  title: 'Factory Check In',
  titleCn: '入厂交接',
  newStatus: ORDER_STATUS_LAUNDRY_IN_PROGRESS,
  dateKey: 'pickup',
  operatorKey: 'checkIner'
};

export const HANDOVER_TYPE_FACTORY_CHECKOUT = {
  diffDays: 0,
  title: 'Factory Check Out',
  titleCn: '出厂交接',
  newStatus: ORDER_STATUS_PENDING_SHOP_RECEIVE,
  dateKey: 'delivery',
  operatorKey: 'checkOuter'
};

export const HANDOVER_TYPE_SHOP_CHECKIN = {
  diffDays: 0,
  title: 'Shop Delivery',
  titleCn: '送还交接',
  newStatus: ORDER_STATUS_PENDING_CUSTOMER_RECEIVE,
  dateKey: 'delivery',
  operatorKey: 'shopIner'
};

export const HANDOVER_TYPE_CUS_COLLECTION = {
  diffDays: 0,
  title: 'Customer Collection',
  titleCn: '客户取件',
  newStatus: ORDER_STATUS_COMPLETED,
  dateKey: 'delivery',
  operatorKey: 'completer'
};

export const HANDOVER_TYPE_DRIVER_DELIVERY = {
  diffDays: 0,
  title: 'Delivery',
  titleCn: '送件',
  newStatus: ORDER_STATUS_COMPLETED,
  dateKey: 'delivery',
  operatorKey: 'completer'
};

export const TASK_STATUS_FAILED = 'Failed';
export const TASK_STATUS_PENDING = 'Pending';
export const TASK_STATUS_ASSIGNED = 'Assigned';
export const TASK_STATUS_ACKNOWLEDGED = 'Acknowledged';
export const TASK_STATUS_COMPLETED = 'Completed';
export const TASK_STATUS_CANCELLED = 'Cancelled';

export function getStatusColor(status) {
  switch(status) {
    case TASK_STATUS_FAILED: return '#F44336';
    case TASK_STATUS_PENDING: return '#2196F3';
    case TASK_STATUS_ASSIGNED: return '#009688';
    case TASK_STATUS_ACKNOWLEDGED: return '#009688';
    case TASK_STATUS_COMPLETED: return '#4CAF50';
    case TASK_STATUS_CANCELLED: return '#9E9E9E';
  }
}

export const UNIT_KG = 'kg';
export const UNIT_SQ = 'sq';
export const UNIT_INCH = 'in';
export const UNIT_BAG = 'bag';
export const UNIT_BUNDLE = 'bundle';
export const UNIT_SET = 'set';
export const UNIT_PAIR = 'pair';
export const UNIT_PCS = 'pcs';

export function unitRequireEnterPcs(unit) {
  return (UNIT_KG===unit)||(UNIT_SQ===unit)||(UNIT_BAG===unit)||(UNIT_INCH===unit);
}

export function unitHasDescimal(unit) {
  return (UNIT_KG===unit)||(UNIT_BAG===unit)||(UNIT_SQ===unit)||(UNIT_INCH===unit);
}

export function unitRejectRequreKeyInUnitQty(unit) {
  return (UNIT_KG===unit)||(UNIT_SQ===unit)||(UNIT_INCH===unit);
}

export const STORE_TYPE_HANG = 'H';
export const STORE_TYPE_FOLD = 'F';
export const STORE_TYPE_HALF_FOLD= 'HF';

export const BUSINESS_TYPE_SHOP = 'shop';
export const BUSINESS_TYPE_VAN = 'van';
export const BUSINESS_TYPE_KIOSK = 'kiosk';
export const BUSINESS_TYPE_HOTEL = 'hotel';
export const BUSINESS_TYPE_TAGGING_POINT = 'tagging';

export function isMobile() {
  if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    return true;
  }
};

export function getBusinessDisplayName(business) {
  return business&&`${business.tagName||business.displayName||''}`;
}

export function getDisplayId(order) {
  if (order&&order.exists) {
    return order.get('originalOrderId')||order.get('orderId');
  } else if (order) {
    return order.originalOrderId||order.orderId;
  } else {
    return '';
  }
}

export function loadExcelFile(file) {
  return new Promise((resolve, reject) => {
    const rABS = true;
    var reader = new FileReader();
    reader.onload = (e) => {
      var data = e.target.result;
      if(!rABS) data = new Uint8Array(data);
      var workbook = XLSX.read(data, {type: rABS ? 'binary' : 'array'});
  
      resolve(workbook);
    };
    if(rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
  });
}

export function convertProductToSelectItem(product, businessId) {
  const businessPricing = product.get(businessId);
  if (businessPricing&&businessPricing.pricings) {
    const { name, nameCn, category, storeType, unit, bundleUnit,
      bundleQty, fixedQty, terms, commissionRate, department } = product.data();

    const pricings = businessPricing.pricings.map(({id,...pricing})=>({pricingId:id,...pricing}));
    return {
      productId: product.id,
      name,
      ...(category&&{category}),
      ...(department&&{department}),
      ...(nameCn&&{nameCn}),
      ...(storeType&&{storeType}),
      ...(unit&&{unit}),
      ...(bundleUnit&&{bundleUnit}),
      ...(commissionRate&&{commissionRate}),
      ...(bundleQty&&{bundleQty}),
      ...((typeof fixedQty === 'number')&&{fixedQty}),
      ...(terms&&{terms}),
      order: product.get('order'),
      pricings,
      ...(businessPricing.teirs&&{teirs: businessPricing.teirs})
    };
  }
}

export function getDeviceInfo() {
  return new Promise((resolve) => {
    const client = new ClientJS();
    const browser = client.getBrowser();
    const device = client.getDevice();
    const engine = client.getEngine();
    const os = client.getOS();
    const ua = client.getUA();

    resolve({
      ...(browser&&{browser: Object.keys(browser).map(key=>`${key}: ${browser[key]}`).join('; ')}),
      ...(device&&{device: Object.keys(device).map(key=>`${key}: ${device[key]}`).join('; ')}),
      ...(engine&&{engine: Object.keys(engine).map(key=>`${key}: ${engine[key]}`).join('; ')}),
      ...(os&&{os: Object.keys(os).map(key=>`${key}: ${os[key]}`).join('; ')}),
      ...(ua&&{ua: ua}),
    });
  })
}

export function getOperator(account) {
  return account&&{
    id: account.uid,
    displayName: account.displayName,
    date: new Date()
  };
}

export function haveAppUpdate(clientVersion, serverVersion) {
  if (!serverVersion) return false;
  if (!clientVersion) return true;
  
  const client = clientVersion.trim().split('.');
  const server = serverVersion.trim().split('.');
  for (let index = 0; index < server.length; index++) {
    const clientCode = (client[index]&&!isNaN(client[index]))?parseInt(client[index]):0;
    const serverCode = (server[index]&&!isNaN(server[index]))?parseInt(server[index]):0;
    if (serverCode>clientCode) {
      return true;
    }
  }
}

export function escapeString(content) {
  return content&&escapeStringRegexp(content);
}

export function toast(content, type='error') {
  return message[type]&&message[type](<span style={{fontSize:'1.6em'}}>{content}</span>);
}

export function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], {type: mimeString});
  return blob;
}

export function searchOrder(search, orders) {
  if (orders&&search) {
    const regexp = new RegExp(escapeString(search), 'i');
    return orders.filter(order => {
      const { orderId, originalOrderId, business, factory, customer, items, adjustments, totalPrice } = order.data();
      return (orderId&&regexp.test(orderId))
        || (originalOrderId&&regexp.test(originalOrderId))
        || (business&&business.displayName&&regexp.test(business.displayName))
        || (factory&&factory.displayName&&regexp.test(factory.displayName))
        || (customer&&customer.id&&regexp.test(customer.id))
        || (customer&&customer.displayName&&regexp.test(customer.displayName))
        || (customer&&customer.email&&regexp.test(customer.email))
        || (customer&&customer.phoneNumber&&regexp.test(customer.phoneNumber))
        || (regexp.test(totalPrice))
        || (items&&items.find(item => regexp.test(item.name)))
        || (adjustments&&adjustments.find(discount => discount.description&&regexp.test(discount.description)));
    });
  }

  return orders;
}

export function formatItemsDisplays(items, currencyMark, chinese, hideLaundryType) {
  return items&&[].concat.apply([], items.map((item) => {
    const { name, nameCn, subType, unit, storeType, teirNotes, contents, contract,
      description, price, quantity, totalPrice, services, inputQty, accs, accessory,
      category, department } = item;
    const addOn = (services&&(services.length>0))?` + ${services.map(service=>service.description).join(', ')}`:'';

    return [{
      title: `${name}${(chinese&&nameCn)?`(${nameCn})`:''}${subType?`/${subType}`:''}${(contents&&(contents!==name))?`: ${contents}`:''}${hideLaundryType?'':` (${description}${addOn})`}${(inputQty&&(unit!==UNIT_BAG))?` ${inputQty} pcs`:''}`,
      description: teirNotes||`${currencyMark||''}${price?price.toFixed(2):'unknow'} / ${unit}`,
      quantity,
      price,
      unit,
      totalPrice,
      storeType,
      pcsQty: calculatePcsQty([item]),
      contract,
      category,
      department
    }, ...(accs?[{
      title: `${accessory||''}(${name}${(chinese&&nameCn)?`(${nameCn})`:``} ${chinese?'配件':'accessory'})`,
      quantity: accs,
      price: 0,
      unit: UNIT_PCS,
      totalPrice: 0,
      storeType,
      category,
      accessory: true
    }]:[])];
  }));
}

export function calculateGrandTotalDetails({items, adjustments, serviceTypeChargeRate, totalServiceTypeCharge,
  totalSurcharge, totalGST, gstRate, totalDiscount, promoCodeAmount, promoCode}, chinese) {
  const surchargeNotes = adjustments&&adjustments.filter(adjust=>adjust.notes&&
    ((adjust.type==PERCENT_SURCHARGE)||(adjust.type===FLAT_SURCHARGE))).map(adjust=>adjust.notes).join('/');
  const discountNotes = adjustments&&adjustments.filter(adjust=>adjust.notes&&
    ((adjust.type==PERCENT_DISCOUNT)||(adjust.type===FLAT_DISCOUNT))).map(adjust=>adjust.notes).join('/');

  return [
    ...(items||[]).filter(item=>item.extraCharge).map(({name, nameCn, percentSurcharge, surchargeType, surchargeValue, notes, surchargeNotes, extraCharge}) =>
      ({title:`${(chinese&&nameCn)||name} (${chinese?`附加收费`:`Surcharge`}${(percentSurcharge||(surchargeType===TYPE_PERCENTAGE))?` +${percentSurcharge||surchargeValue}%`:''})`, description: notes||surchargeNotes, amount: extraCharge})),
    ...(items||[]).filter(item=>item.totalAddOnService).map(({name, nameCn, quantity, foc, reject, rejectUnitQty, unit, services, totalAddOnService}) => {
      const chargeServices = services&&services.filter(svc=>svc.price);
      return {title: `${(chinese&&nameCn)||name}${(chargeServices&&(chargeServices.length>0))?` add ${chargeServices.map(({description,descriptionCn})=>(chinese&&descriptionCn)||description).join('/')}`:''}`,
        description: chargeServices&&chargeServices.map(({description,descriptionCn,price})=>`${(chinese&&descriptionCn)||description} (${price.toFixed(2)})`).join(', '),
        amount: totalAddOnService, quantity: quantity - (foc||0) - (rejectUnitQty||((unit===UNIT_PCS)&&reject)||0)};
    }),
    ...(items||[]).filter(item=>item.extraDiscount).map(({name, nameCn, discountType, discountValue, discountNotes, extraDiscount}) =>
      ({title:`${(chinese&&nameCn)||name} (${chinese?`折扣`:`Discount`}${(discountType===TYPE_PERCENTAGE)?` -${discountValue}%`:''})`, discount: true, description: discountNotes, amount: extraDiscount})),
    ...(items||[]).filter(item=>item.totalFOC).map(({name, nameCn, foc, totalFOC}) =>
      ({title: `${(chinese&&nameCn)||name} (FOC)`, amount: totalFOC, quantity: foc, discount: true})),
    ...(items||[]).filter(item=>item.totalReject).map(({name, nameCn, reject, rejectUnitQty, totalReject}) =>
      ({title: `${(chinese&&nameCn)||name} (Reject)`, amount: totalReject, quantity: rejectUnitQty||reject, discount: true})),
    ...(totalServiceTypeCharge?[{title:`${chinese?'特别服务收费':'Service Type Charge'}(${serviceTypeChargeRate}%)`, amount:totalServiceTypeCharge}]:[]),
    ...(totalSurcharge?[{title:`${chinese?'特别附加费':'Surcharge'}${surchargeNotes?` (${surchargeNotes})`:''}`, amount: totalSurcharge}]:[]),
    ...(totalDiscount?[{title:`${chinese?'特别折扣':'Discount'}${discountNotes?` (${discountNotes})`:''}`, amount: totalDiscount, discount: true}]:[]),
    ...(promoCodeAmount?[{title:`${chinese?'优惠码':'Promo Code'}${promoCode?` ${promoCode.code}`:''}`, amount: promoCodeAmount, discount: true}]:[]),
    ...(totalGST?[{title:`${chinese?'GST':'GST'}${gstRate?` (${gstRate}%)`:``}`, amount: totalGST}]:[]),
  ];
}

export function calculateInvoiceAmount({daySales, payableTo}) {
  let subTotal = 0, gstAmount = 0, surchargeTotal = 0, serviceFeeTotal = 0, discountTotal = 0;
  daySales&&daySales.forEach(({pricing, surcharges, discounts, serviceFee}) => {
    if (typeof surcharges === 'number') {
      surchargeTotal += surcharges;
    }
    if (typeof serviceFee === 'number') {
      serviceFeeTotal += serviceFee;
    }
    if (typeof discounts === 'number') {
      discountTotal += discounts;
    }
    for (const key in pricing) {
      subTotal = formatPrice(subTotal + pricing[key]);
    }
  });

  if (payableTo.gstRate) {
    gstAmount = formatPrice(subTotal * payableTo.gstRate / 100);
  }

  return { subTotal, gstAmount, surchargeTotal, discountTotal,
    serviceFeeTotal, total: formatPrice(subTotal+Math.abs(serviceFeeTotal)+Math.abs(surchargeTotal)-Math.abs(discountTotal)+gstAmount) };
}

export function toFormattedDate(date, start, end) {
  return {
    unix: date.toDate(),
    display: date.format('HH:mm MMM Do YYYY'),
    short: date.format('HH:mm DD/MM'),
    year: date.format('YYYY'),
    month: date.format('YYYY-MM'),
    date: date.format('YYYY-MM-DD'),
    ...((typeof start === 'number')&&{
      start
    }),
    ...((typeof end === 'number')&&{
      end
    })
  };
}

export async function processPhotoUploads(firebase, orderId, photos) {
  if (photos) {
    const results = [];
    for (const photo of photos) {
      if (false) { // photo.indexOf('data:')===0
        // const photoUrl = await firebase.storage().ref(`rejectitems/${orderId}/${Date.now()}.jpeg`).putString(photo, 'data_url')
        //   .then(snapshot => snapshot.ref.getDownloadURL());
        results.push(photo);
      } else {
        results.push(photo);
      }
    }
    return results;
  }
}

export async function processItemsPhotoUploads(firebase, orderId, items) {
  // Process photo upload
  if (items) {
    for (let item of items) {
      if (item.rejectPhotos) {
        const rejectPhotos = await processPhotoUploads(firebase, orderId, item.rejectPhotos)
        item.rejectPhotos = rejectPhotos;
      }
    }
  }

  return items;
}

export async function getOrderId(firebase, factoryId, orderDate, orderIdPath) {
  const settingDoc = await Promise.resolve(orderIdPath&&firebase.firestore().doc(orderIdPath).get());

  return firebase.firestore().runTransaction(async t => {
    if (settingDoc&&(typeof settingDoc.get('orderNo') === 'number')) {
      const settings = await t.get(settingDoc.ref);
      const newCount = settings.get('orderNo')+1;
      await t.update(settingDoc.ref, {orderNo: newCount});
      return `${newCount}`;
    } else {
      const counterRef = firebase.firestore().doc(`ordercounter/${orderDate.format('YYYY-MM-DD')}`);
      const counter = await t.get(counterRef);
      const newCount = (counter.get('count')||0)+1;
      const factoryCount = (counter.get(factoryId)||0)+1;
      await t.set(counterRef, { date: orderDate.toDate(), count: newCount, [factoryId]: factoryCount }, {merge: true});
      return `${orderDate.format('YYMMDD')}${newCount}`;
    }
  });
}

export async function submitCharges(firebase, payment, account, {business, orderId, orderDisplayId, token, items}) {
  const charge = newCharge({business, orderId, orderDisplayId, type: payment.type, amount: payment.amount, account, token, items});
  await firebase.firestore().collection(`charges`).add(charge);
}

export function findNextWorkingDay(now, days, examptSunday, examptPublicHoilday, publichoildays) {
  if ((days<=0)||(!examptSunday&&!examptPublicHoilday)) {
    return now.add(days, 'days');
  } else {
    let workingDays = [];
    while(workingDays.length<days) {
      now.add(1, 'days');
      if (examptSunday&&(now.day()===0)) {
        continue;
      }

      if (examptPublicHoilday&&publichoildays&&(publichoildays.indexOf(now.format('YYYY-MM-DD'))>=0)) {
        continue;
      }

      workingDays.push(now.clone());
    }

    return workingDays[workingDays.length-1];
  }
}

const removeEmpty = (obj) => {
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') removeEmpty(obj[key]);
    else if (obj[key] === undefined) delete obj[key];
  });
  return obj;
};

export function getOrderCommissionRate(items) {
  if (!items) return;
  let rates = [];
  items.forEach(item => {
    if (item.commissionRate&&!rates.find(rate=>rate===item.commissionRate)) {
      rates.push(item.commissionRate);
    }
  });

  // only single rate works
  if (rates.length===1) {
    return rates[0];
  }
}

export async function placeOrder(firebase, creator, { hbInvoice, originalOrderId, businessId, fixedTotal, createDate,
    pickupDate, pickupDays, businessName, businessTagName, factoryId, notes, customer, items, adjustments,
    createSign, storage, tagQuantity, printQty, serviceTypeChargeRate, deliveryDays, deliveryDate, invoiceCompany,
    tagNotes, photos, payment, trackingId, paymentTracking, promoCode, hangQty, foldQty, bagQty, laundryQty, dryCleanQty, pressingQty },
  { businessDoc, settingDoc, chinese, serviceFeeRate, gstRate, businessMode, businessType,
    oneDeliveryOrderPerDay, tagDayDiffs, completed, loginShortName, factoryWorker, tagName,
    orderNoMap, orderIdPath, reuqireDayIndex, dayIndexShareKey, minimumOrder, roundPrice, driverOrder }) {
  const orderDate = createDate||moment();
  if (!deliveryDate&&(typeof deliveryDays === 'number')) {
    deliveryDate = moment().add(deliveryDays, 'days');
  }
  if (!pickupDate&&(typeof pickupDays === 'number')) {
    pickupDate = moment().add(pickupDays, 'days');
  }

  if (oneDeliveryOrderPerDay&&deliveryDate) {
    const existingOrders = await firebase.firestore().collection(`orders`)
      .where('delivery.date', '==', deliveryDate.format('YYYY-MM-DD'))
      .where('business.id', '==', businessId)
      .where('factory.id', '==', factoryId)
      .get();

    if (!existingOrders.empty&&existingOrders.docs[0]) {
      const order = existingOrders.docs[0];
      throw {
        message: chinese?`重复订单`:`Duplicated order`,
        duplicatedOrder: order
      };
    }
  }

  const defaultPickup = businessMode?orderDate.clone().add(1,'days'):orderDate.clone();
  const business = {
    id: businessId,
    ...(businessName&&{displayName: businessName}),
    ...((tagName||businessTagName)&&{tagName: tagName||businessTagName}),
  };

  const manualQtys = {
    ...(tagQuantity&&!isNaN(tagQuantity)&&{tagQuantity: parseInt(tagQuantity)}),
    ...(hangQty&&!isNaN(hangQty)&&{hangQty: parseInt(hangQty)}),
    ...(foldQty&&!isNaN(foldQty)&&{foldQty: parseInt(foldQty)}),
    ...(laundryQty&&!isNaN(laundryQty)&&{laundryQty: parseInt(laundryQty)}),
    ...(dryCleanQty&&!isNaN(dryCleanQty)&&{dryCleanQty: parseInt(dryCleanQty)}),
    ...(pressingQty&&!isNaN(pressingQty)&&{pressingQty: parseInt(pressingQty)}),
  };

  // Order day index if needed
  let dayIndex;
  if (reuqireDayIndex) {
    dayIndex = await firebase.firestore().runTransaction(async t => {
      const counterRef = firebase.firestore().doc(dayIndexShareKey?`factories/${factoryId}/sharedcounter/${dayIndexShareKey}/dates/${moment().format('YYYY-MM-DD')}`:`businesses/${businessId}/ordercounter/${moment().format('YYYY-MM-DD')}`);
      const counter = await t.get(counterRef);
      const newCount = (counter.get('count')||0)+1;
      await t.set(counterRef, { date: new Date(), count: newCount });
      return newCount;
    });
  }

  const pricings = calculateOrderPrice({minimumOrder, items, adjustments, serviceFeeRate, gstRate, serviceTypeChargeRate, roundPrice, fixedTotal});

  let orderData = {
    ...(hbInvoice&&{hbInvoice}),
    ...manualQtys,
    ...(dayIndex&&{dayIndex}),
    ...(((!manualQtys.tagQuantity)&&(manualQtys.hangQty||manualQtys.foldQty))&&{
      tagQuantity: (manualQtys.hangQty||0) + (manualQtys.foldQty||0)
    }),
    ...(((!manualQtys.tagQuantity)&&(manualQtys.laundryQty||manualQtys.dryCleanQty||manualQtys.pressingQty))&&{
      tagQuantity: (manualQtys.laundryQty||0) + (manualQtys.dryCleanQty||0) + (manualQtys.pressingQty||0)
    }),
    ...(printQty&&!isNaN(printQty)&&{printQty: parseInt(printQty)}),
    ...(bagQty&&{bagQty}),
    ...(photos&&{photos}),
    ...(roundPrice&&{roundPrice}),
    ...(trackingId&&{trackingId}),
    ...(tagNotes&&{tagNotes}),
    ...(originalOrderId&&{originalOrderId}),
    ...(customer&&{customer}),
    ...(notes&&{notes}),
    ...(invoiceCompany&&{company: invoiceCompany}),
    ...((typeof minimumOrder === 'number')&&{minimumOrder}),
    ...(storage&&{storage}),
    ...((typeof fixedTotal === 'number')&&{fixedTotal}),
    ...(paymentTracking&&{paymentTracking}),
    ...(promoCode&&{promoCode}),
    business,
    factory: {
      id: factoryId
    },
    creator: {
      id: creator.uid,
      ...(creator.displayName&&{displayName: creator.displayName}),
      ...(loginShortName&&{shortName: loginShortName}),
      date: new Date()
    },
    ...(gstRate&&(typeof gstRate === 'number')&&{gstRate}),
    ...(serviceFeeRate&&(typeof serviceFeeRate === 'number')&&{serviceFeeRate}),
    ...(serviceTypeChargeRate&&(typeof serviceTypeChargeRate === 'number')&&{serviceTypeChargeRate}),
    ...pricings,
    created: toFormattedDate(orderDate),
    pickup: toFormattedDate(pickupDate||(!deliveryDate||(defaultPickup.isBefore(deliveryDate))?defaultPickup:deliveryDate)),
    ...(deliveryDate&&{
      delivery: toFormattedDate(deliveryDate),
      ...((typeof tagDayDiffs === 'number')&&{
        tagging: toFormattedDate(deliveryDate.clone().add(tagDayDiffs, 'days'))
      })
    }),
    ...(createSign&&{createSign}),
    status: businessMode?((businessType===BUSINESS_TYPE_VAN)?
      ORDER_STATUS_PENDING_FACTORY_RECEIVE:ORDER_STATUS_PENDING_PICKUP):(completed?ORDER_STATUS_COMPLETED:
        ((driverOrder||!pricings.totalPrice)?ORDER_STATUS_PENDING_FACTORY_RECEIVE:ORDER_STATUS_LAUNDRY_IN_PROGRESS))
  };

  orderData = removeEmpty(orderData);

  if (!originalOrderId) {
    const orderNo = settingDoc&&orderNoMap&&orderNoMap[creator.uid];
    if (typeof orderNo === 'number') {
      if (orderNo>0) {
        originalOrderId = orderNo + 1;
        settingDoc.ref.update({[`orderNoMap.${creator.uid}`]: originalOrderId});
        originalOrderId = `${originalOrderId}`;
      } else if (factoryWorker&&(typeof factoryWorker.get('autoOrderNo') === 'number')) {
        // Fallback support
        originalOrderId = factoryWorker.get('autoOrderNo') + 1;
        settingDoc.ref.update({[`orderNoMap.${creator.uid}`]: originalOrderId});
        originalOrderId = `${originalOrderId}`;
      }
    }
  }

  let orderRef;
  if (originalOrderId) {
    orderRef = firebase.firestore().collection(`orders`).doc();
    if (navigator.onLine) {
      await orderRef.set({...orderData, originalOrderId, orderId: originalOrderId});
      if (payment) {
        await submitCharges(firebase, payment, creator,
          {business, orderId: orderRef.id, orderDisplayId: originalOrderId, items});
      }
    } else {
      orderRef.set({...orderData, originalOrderId, orderId: originalOrderId})
        .then(() => payment&&submitCharges(firebase, payment, creator,
          {business, orderId: orderRef.id, orderDisplayId: originalOrderId, items}));
    }

    if (businessDoc&&originalOrderId&&!isNaN(originalOrderId)) {
      try {
        businessDoc.ref.update({originalOrderId: `${originalOrderId}`});
      } catch (err) {
        // Ignore
        console.log(err);
      }
    }
  } else {
    orderRef = firebase.firestore().collection(`orders`).doc();
    let orderId;
    if (businessDoc&&businessDoc.get('useOwnNo')&&businessDoc.get('originalOrderId')&&!isNaN(businessDoc.get('originalOrderId'))) {
      orderId = await firebase.firestore().runTransaction(async t => {
        const settings = await t.get(businessDoc.ref);
        const newCount = parseInt(settings.get('originalOrderId'))+1;
        await t.update(businessDoc.ref, {originalOrderId: newCount});
        return `${newCount}`;
      });
    } else {
      orderId = await getOrderId(firebase, factoryId, orderDate, orderIdPath);
    }

    await orderRef.set({...orderData, orderId, originalOrderId: orderId})
      .then(() => payment&&submitCharges(firebase, payment, creator,
        {business, orderId: orderRef.id, orderDisplayId: orderId, items}));
  }

  if (customer) {
    firebase.firestore().doc(`businesses/${businessId}/users/${customer.id}`).get()
      .then(customer => customer.exists&&customer.ref.update({orderCount:(customer.get('orderCount')||0)+1}));
  }

  // firebase.firestore().collection(`orders/${orderId}/histories`).add({
  //   date: new Date(),
  //   title: `Order created`,
  //   ...(creator.displayName&&{
  //     operator: creator.displayName
  //   })
  // });

  return orderRef;
}