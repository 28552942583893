import { calculatePcsQty } from "./printerUtils";
import { UNIT_PCS } from "./utils";
import { PROMO_TYPE_FLAT, PROMO_TYPE_PERCENT } from "./constants";

export const PERCENT_DISCOUNT = 'percent_off';
export const FLAT_DISCOUNT = 'amount_off';
export const PERCENT_SURCHARGE = 'percent_surcharge';
export const FLAT_SURCHARGE = 'amount_surcharge';

export function formatPrice(price) {
  return Math.round(price * 100) / 100;
}

export function calculateOrderPrice({items, adjustments, serviceTypeChargeRate,
  serviceFeeRate, gstRate, fixedTotal, promoCode, minimumOrder, roundPrice}) {
  let subTotalPrice = 0, totalReject = 0, totalAddOnCharge = 0, totalExtraCharge = 0,
    totalServiceTypeCharge = 0, totalServiceFee = 0, totalFOC = 0, totalGST = 0,
    totalSurcharge = 0, totalDiscount = 0, totalPrice = 0, totalExtraDiscount = 0,
    promoCodeAmount = 0;

  if (typeof fixedTotal !== 'number') {
    items&&items.forEach(item => {
      if (item.teirs&&(item.teirs.length>0)) {
        item.totalPrice = 0;
        let countingQty = 0, notes = [];
        const highToLow = item.teirs.sort((a,b)=>b.qty-a.qty);
        for (let index = 0; index < highToLow.length; index++) {
          const { qty, stick, pricings } = highToLow[index];
          if (item.quantity >= qty) {
            // Tacking effect
            const pricing = pricings&&pricings.find(pricing=>(pricing.id===item.pricingId)&&((!item.subType||pricing.price)||(item.subType&&pricing[`price${item.subType}`])));
            if (pricing) {
              // Pricing found
              let effectQty;
              if (stick) {
                // All quantity follow this price
                effectQty = item.quantity-countingQty;
              } else {
                // Only quantity alove qty effected
                effectQty = item.quantity-qty;
              }

              const unitPrice = pricing[`price${item.subType||''}`];
              countingQty += effectQty;
              item.totalPrice = formatPrice((item.totalPrice||0) + (unitPrice * effectQty));
              notes.push(`$${unitPrice}x${effectQty}(Teir ${qty}+)`);

              if (countingQty >= item.quantity) {
                // All calculated
                break;
              }
            }
          }
        }

        if (countingQty > 0) {
          if (item.quantity > countingQty) {
            const restQty = item.quantity-countingQty;
            item.totalPrice = formatPrice((item.totalPrice||0) + ((item.price||0) * restQty));
            notes.push(`$${item.price||0}x${restQty}(Rest)`);
          }
          item.teirNotes = notes.join(' + ');
        } else {
          // Follow the standard price
          item.totalPrice = formatPrice((item.price||0) * (item.quantity||0));
          delete item.teirNotes;
        }
      } else {
        item.totalPrice = formatPrice((item.price||0) * (item.quantity||0));
        delete item.teirNotes;
      
        // No reject or foc refund for tiered pricing
        if ((item.unit===UNIT_PCS)&&(typeof item.reject === 'number')) {
          item.totalReject = formatPrice((item.price||0) * item.reject);
        } else if (typeof item.rejectUnitQty === 'number') {
          item.totalReject = formatPrice((item.price||0) * item.rejectUnitQty);
        } else {
          item.totalReject = 0;
        }
    
        if (typeof item.foc === 'number') {
          item.totalFOC = formatPrice((item.price||0) * item.foc);
        } else {
          item.totalFOC = 0;
        }
      }
  
      subTotalPrice = formatPrice(subTotalPrice+(item.totalPrice||0));
      totalReject = formatPrice(totalReject+(item.totalReject||0));
      totalFOC = formatPrice(totalFOC+(item.totalFOC||0));
  
      if (item.services) {
        const pieces = calculatePcsQty([item]);
        let totalAddOnService = 0;
        item.services.forEach(service => {
          if (service.price) {
            totalAddOnService = formatPrice(totalAddOnService + (service.price*pieces));
          }
        });
        item.totalAddOnService = totalAddOnService;
        totalAddOnCharge = formatPrice(totalAddOnCharge + totalAddOnService);
      } else {
        item.totalAddOnService = 0;
      }
  
      let extraCharge = 0, extraDiscount = 0;
      if (typeof item.flatSurcharge === 'number') {
        extraCharge = formatPrice(extraCharge + item.flatSurcharge);
      }
      if (typeof item.percentSurcharge === 'number') {
        extraCharge = formatPrice(extraCharge + (Math.max(0, (item.totalPrice||0) - (item.totalReject||0) - (item.totalFOC||0)) * item.percentSurcharge / 100));
      }
      if (typeof item.surchargeValue === 'number') {
        if (item.surchargeType === 'flat') {
          extraCharge = formatPrice(item.surchargeValue);
        } else if (item.surchargeType === 'percentage') {
          extraCharge = formatPrice((Math.max(0, (item.totalPrice||0) - (item.totalReject||0) - (item.totalFOC||0)) * item.surchargeValue / 100));
        }
      }
      if (typeof item.discountValue === 'number') {
        if (item.discountType === 'flat') {
          extraDiscount = formatPrice(item.discountValue);
        } else if (item.discountType === 'percentage') {
          extraDiscount = formatPrice((Math.max(0, (item.totalPrice||0) - (item.totalReject||0) - (item.totalFOC||0)) * item.discountValue / 100));
        }
      }
  
      item.extraCharge = extraCharge;
      item.extraDiscount = extraDiscount;
      totalExtraCharge = formatPrice(totalExtraCharge + extraCharge);
      totalExtraDiscount = formatPrice(totalExtraDiscount + extraDiscount);
    });
  
    totalPrice = Math.max(0, formatPrice(subTotalPrice-totalFOC-totalReject+totalAddOnCharge+totalExtraCharge-totalExtraDiscount));
  
    if (typeof serviceTypeChargeRate === 'number') {
      totalServiceTypeCharge = formatPrice(totalPrice*serviceTypeChargeRate/100);
      totalPrice = formatPrice(totalPrice + totalServiceTypeCharge);
    }
  
    adjustments&&adjustments.forEach(adjust => {
      if (adjust.value&&!isNaN(adjust.value)) {
        if (adjust.type===PERCENT_DISCOUNT) {
          adjust.amount = -Math.abs(formatPrice(totalPrice*adjust.value/100));
        } else if (adjust.type===FLAT_DISCOUNT) {
          adjust.amount = -Math.abs(formatPrice(adjust.value));
        } else if (adjust.type===PERCENT_SURCHARGE) {
          adjust.amount = Math.abs(formatPrice(totalPrice*adjust.value/100));
        } else if (adjust.type===FLAT_SURCHARGE) {
          adjust.amount = Math.abs(formatPrice(adjust.value));
        }

        if (!adjust.minimum) {
          if (typeof adjust.amount === 'number') {
            if (adjust.amount>0) {
              totalSurcharge = formatPrice(totalSurcharge+Math.abs(adjust.amount));
            } else if (adjust.amount<0) {
              totalDiscount = formatPrice(totalDiscount+Math.abs(adjust.amount));
            }
          }
        }
      }
    });

    const minimumIndex = adjustments?adjustments.findIndex(adjustment=>adjustment.minimum):-1;
    if (typeof minimumOrder === 'number') {
      const beforePromo = Math.max(0, formatPrice(totalPrice+totalSurcharge-totalDiscount));
      if (beforePromo < minimumOrder) {
        const minimumSurcharge = formatPrice(minimumOrder - beforePromo);

        if (minimumIndex>=0) {
          adjustments[minimumIndex].value = minimumSurcharge;
          adjustments[minimumIndex].amount = minimumSurcharge;
        } else {
          if (!adjustments) {
            adjustments = [];
          }
          adjustments.push({
            type: 'amount_surcharge',
            value: minimumSurcharge,
            amount: minimumSurcharge,
            description: `Minimum order surcharge`,
            minimum: true
          });
        }

        totalSurcharge = formatPrice(totalSurcharge+minimumSurcharge);
      } else if (minimumIndex>=0) {
        adjustments = [...adjustments.slice(0, minimumIndex), ...adjustments.slice(minimumIndex+1)];
      }
    } else if (minimumIndex>=0) {
      adjustments = [...adjustments.slice(0, minimumIndex), ...adjustments.slice(minimumIndex+1)];
    }

    if (promoCode&&(typeof promoCode.value === 'number')&&((typeof promoCode.minimum !== 'number')||(totalPrice>=promoCode.minimum))) {
      if (promoCode.type === PROMO_TYPE_FLAT) {
        promoCodeAmount = promoCode.value;
      } else if (promoCode.type === PROMO_TYPE_PERCENT) {
        promoCodeAmount = Math.abs(formatPrice(totalPrice*promoCode.value/100));
        if (typeof promoCode.cap === 'number') {
          promoCodeAmount = Math.min(promoCodeAmount, promoCode.cap);
        }
      }
    }
  
    totalPrice = Math.max(0, formatPrice(totalPrice+totalSurcharge-totalDiscount-promoCodeAmount));
  } else {
    subTotalPrice = fixedTotal;
    totalPrice = fixedTotal;
  }

  if (typeof serviceFeeRate === 'number') {
    totalServiceFee = formatPrice(totalPrice*serviceFeeRate/100);
  }

  if (typeof gstRate === 'number') {
    totalGST = formatPrice(totalPrice*gstRate/100);
  }

  totalPrice = formatPrice(totalPrice+totalServiceFee+totalGST);

  let roundAmount = 0;

  if (roundPrice) {
    const priceSplit = `${totalPrice}`.split('.');
    if ((priceSplit.length===2)&&(priceSplit[1].length===2)) {
      const lastDigit = parseInt(priceSplit[1][1]);
      if ((lastDigit===1)||(lastDigit===6)) {
        // Floor
        roundAmount = -0.01;
      } else if ((lastDigit===2)||(lastDigit===7)) {
        // Floor
        roundAmount = -0.02;
      } else if ((lastDigit===3)||(lastDigit===8)) {
        // Ceil
        roundAmount = 0.02;
      } else if ((lastDigit===4)||(lastDigit===9)) {
        // Ceil
        roundAmount = 0.01;
      }
    }
  }

  totalPrice = formatPrice(totalPrice+roundAmount);

  return {
    subTotalPrice,
    totalReject,
    totalFOC,
    totalAddOnCharge,
    totalExtraCharge,
    totalExtraDiscount,
    totalServiceTypeCharge,
    totalSurcharge,
    totalDiscount,
    totalServiceFee,
    promoCodeAmount,
    totalGST,
    totalPrice,
    items: items||[],
    adjustments: adjustments||[],
    roundAmount
  };
}